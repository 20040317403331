import React, { useState, useEffect, VoidFunctionComponent } from 'react'
import styles from './verification-code-popup.module.scss'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Button, Form, Toast } from 'antd-mobile'
import NumberInput from '@/components/number-input/number-input'
import { isPhone, isIOS } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import indexApi from '@/apis'
import { logout, selectUserInfo } from '@/store/user'

interface Props {
  callBack: () => void
}

const DetainmentPopup: React.FC<Props> = props => {
  const { callBack } = props
  const dispatch = useAppDispatch()

  // 关闭弹窗
  const close = () => {
    callBack()
  }

  const [form] = Form.useForm()
  // 手机号码
  const [phone, setPhone] = useState('')
  // 验证码
  const [code, setCode] = useState('')

  // 检验手机号码
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkMobile = (_: any, value: string) => {

    if (phone) {

      if (!isPhone(phone)) {
        return Promise.reject(new Error('手机号码格式有误'))
      }
      return Promise.resolve()
    }
    return Promise.reject(new Error('请输入手机号码'))
  }

  // 检验验证码
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkCode = (_: any, value: string) => {

    if (code) {
      // eslint-disable-next-line no-magic-numbers
      if (code.length !== 6) {
        return Promise.reject(new Error('验证码长度为6'))
      }
      return Promise.resolve()
    }
    return Promise.reject(new Error('请输入验证码'))
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  // 获取验证码
  const getCode = async() => {
    if (!phone) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(phone)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await indexApi.logOutVerifyCode({
        mobile: +phone
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }

    Toast.show('获取验证码成功')
  }

  // 确认注销
  const confirmLogOut = async() => {
    if (!code) {
      return Toast.show('请输入验证码')
    }
    try {
      Toast.show({
        icon: 'loading',
        content: '正在为您注销…',
        duration: 0,
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await indexApi.cancellation({
        code,
      })
      Toast.clear()
      handleLayout()
    } catch (error:any) {
      // eslint-disable-next-line no-console
    }
  }

  const handleLayout = () => {
    dispatch(logout())
    if (isIOS) {
      window.webkit.messageHandlers.iosLoginOut.postMessage(null)
    }
  }

  return <section className={styles.modalPage}>
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.title}>确认注销</div>
        <div className={styles.formBox}>
          <Form form={form} initialValues={{ code: '' }}>
            <Form.Item
              name="phone"
              label=""
              rules={[
                {
                  required: true,
                  validateTrigger: 'onBlur',
                  validator: checkMobile,
                }
              ]}
            >
              <div className={styles.inputBox}>
                <NumberInput
                  placeholder="请输入手机号"
                  value={phone}
                  numberFormat={{
                    decimal: false,
                    negative: false,
                    leadingZero: false,
                    maxLength: 11
                  }}
                  onChange={val => {
                    setPhone(val)
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item name="code" label="" rules={[
              {
                required: true,
                validateTrigger: 'onBlur',
                validator: checkCode,
              }
            ]}>
              <div className={styles.inputBox}>
                <NumberInput
                  placeholder="请输入验证码"
                  value={code}
                  numberFormat={{
                    decimal: false,
                    negative: false,
                    maxLength: 6
                  }}
                  onChange={val => {
                    setCode(val)
                  }}
                />
                {
                  time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
                    : <span className={styles.code} onClick={getCode}>获取验证码</span>
                }

              </div>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.btnBox}>
          <Button className={classNames(styles.btn, styles.cancelBtn)} onClick={close}>取消</Button>
          <Button className={styles.btn} onClick={() => {
            confirmLogOut()
          }}>确认</Button>
        </div>
      </div>
      {/* <div className={styles.close} onClick={close}></div> */}
    </div>
  </section>
}

export default DetainmentPopup