// 注释账号
import React, { useState, useEffect } from 'react'
import Header from '@/components/header/header'
import styles from './cancel-account.module.scss'
import { List, Modal, Button } from 'antd-mobile'

import VerificationCodePopup from '../verification-code-popup/verification-code-popup'
interface Props {}

const ApplicationRecord: React.FC<Props> = () => {
  // 是否显示弹窗
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)

  // 申请注销
  const logoutHandler = () => {
    setIsShowPopup(true)
  }

  return (
    <div className={styles.cancelAccount}>
      <Header title="注销账号" />
      <div className={styles.pageTop}>
        <img className={styles.pageTopIcon} src={require('@imgs/yqzs/cancel-account.png')} />
        <div className={styles.pageTopText}>确认是否注销？</div>
      </div>
      <div className={styles.splitLine}></div>
      <div className={styles.pageBottom}>
        <div className={styles.cancelAccountTips}>
          <div className={styles.tipsItem}>
            <div className={styles.tipsItemCircle}></div>
            <div className={styles.tipsItemText}>手机号、个人资料等信息将清空且无法恢复</div>
          </div>
          <div className={styles.tipsItem}>
            <div className={styles.tipsItemCircle}></div>
            <div className={styles.tipsItemText}>帐号一旦被注销将不可恢复，请在操作前自行备份帐号相关的所有信息和数据</div>
          </div>
          <div className={styles.tipsItem}>
            <div className={styles.tipsItemCircle}></div>
            <div className={styles.tipsItemText}>帐号注销不代表注销前的账号行为和相关责任得到豁免或减轻</div>
          </div>
        </div>
      </div>
      <Button
        onClick={logoutHandler}
        className={styles.btn}
        block
        shape="rounded"
        color="primary"
      >
        申请注销
      </Button>
      {
        isShowPopup && <VerificationCodePopup callBack={() => setIsShowPopup(false)}/>
      }
    </div>
  )
}

export default ApplicationRecord
