/* eslint-disable no-magic-numbers */
import React, { useCallback, useState } from 'react'
import styles from './apply_limit.module.scss'
import classNames from 'classnames'
import { Input, Toast } from 'antd-mobile'
import { parseNum } from '@bihu/common-js'
import BigNumber from 'bignumber.js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import Header from '@/components/header/header'
import { useNavigate } from 'react-router-dom'

interface Props {
  type?: 'getQuota', // 领取额度
}

const Skin3: React.FC<Props> = props => {
  const { type } = props
  const navigate = useNavigate()
  const hostNameObj = getHostNameObj()

  // 最小金额1万
  const [min] = useState(10000)
  // 最大金额20万
  const [max] = useState(200000)

  // 借款金额
  const [amount, setAmount] = useState<string>(parseNum(min))

  // 当前选中期数
  const [currentPeriods, setCurrentPeriods] = useState<number>(12)

  // 借款金额改变
  const amountOnChange = (str: string) => {

    str = str.replace(/[^\d]/g, '')
    let newValue:number | string = str
    if (+str > max) {
      newValue = max
    }
    newValue = parseNum(newValue as number)
    if (newValue && newValue !== '0') {
      setAmount(newValue)
    } else {
      setAmount('')
    }
  }

  // 失去焦点
  const amountOnBlur = () => {
    let str = amount.replace(/[^\d]/g, '')

    if (+str < min) {
      setAmount(parseNum(min))
    }
  }

  // 月利率
  const [monthlyInterestRate] = useState(0.006)

  // 每月需还金额 = （借款金额 + 总利息）/ 期数
  const monthRepayAmountFn = useCallback((currentMonth:number) => {
    const amountNew = amount.replace(',', '')

    if (!amountNew) {
      return '-'
    }

    let val = new BigNumber(amountNew).plus(totalInterest(currentMonth))
      .div(currentMonth)
      .toFixed(2)

    return val
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  // 总利息 = 借款金额*月利率*期数
  const totalInterest = useCallback((currentMonth:number) => {
    const amountNew = amount.replace(',', '')

    if (!amountNew) {
      return '-'
    }

    let val = new BigNumber(amountNew).multipliedBy(monthlyInterestRate)
      .multipliedBy(currentMonth)
      .toFixed(2)

    return val
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  // 选择协议
  const [checked, setChecked] = useState(false)
  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }
  const toNextPage = () => {
    if (!checked) {
      return Toast.show('请勾选注册及隐私协议')
    }
    navigate('/apply_auth_form')
  }

  // 跳转页面
  const openView = (path: string) => {
    navigate(`/${path}-agreement?isShowHeader=true`)
  }

  return (
    <div className={styles.fullPage}>
      <Header title="有钱助手" />
      <header className={styles.header}>
        <div className={styles.logoBox}>
          <img className={styles.logo} src={hostNameObj.logo} alt="logo" />
          有钱来
        </div>
        <div className={styles.textTip}>
          今日放款率 <i className={styles.percentage}></i>
        </div>

        <div className={styles.inputBox}>
          <span>
            ￥
          </span>
          <Input
            placeholder=""
            value={amount}
            onChange={amountOnChange}
            onBlur={amountOnBlur}
          />
          <div className={styles.text}>
            (金额可修改)
            {/* <span className={styles.clear} onClick={() => setAmount(parseNum(min))}>x</span> */}
          </div>
        </div>

        <div className={styles.warmPrompt}>
          <span className={styles.tag}>温馨提示</span>
          根据个人资质选择对应金额，放款成功率更高
        </div>
      </header>

      <div className={styles.line}></div>

      <div className={styles.periods}>
        {
          [12, 6, 3].map(item => {
            return <div key={item} className={classNames(styles.item, item === currentPeriods && styles.active)} onClick={() => setCurrentPeriods(item)}>
              <div className={styles.left}>
              分{item}月还
              </div>
              <div className={styles.right}>
                <div className={styles.textBox}>
                  <div className={styles.value}>首期预估还￥{monthRepayAmountFn(item)}</div>
                  <div className={styles.label}>借满{item}个月，总利息￥{totalInterest(item)}</div>
                </div>
                <div className={classNames(styles.select)}></div>
              </div>
            </div>
          })
        }
        <div className={styles.limitedTimeActivity}>
          <div className={styles.left}></div>
          <div>
            2款快速放款产品
            <span className={styles.tag}>今日有效</span>
          </div>
        </div>
      </div>

      <div className={styles.line}></div>

      <div className={styles.formBox}>
        <div className={classNames(styles.footerBtn, styles.active)} onClick={toNextPage}>查看我的额度</div>
        <div className={styles.agreement}>
          <i className={classNames(styles.checked, checked && styles.active)} onClick={checkedTaggle}></i>
                我已阅读并同意
          <span
            className={styles.primary}
            onClick={() => {
              openView('register')
            }}
          >
            《注册协议》
          </span>
          <span className={styles.primary} onClick={() => {
            openView('privacy')
          }}>《隐私政策》</span>
        </div>
      </div>
    </div>
  )
}

export default Skin3
