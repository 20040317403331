// 内嵌页
import Header from '@/components/header/header'
import React, { useEffect, useRef, useState } from 'react'
import styles from './inline-page.module.scss'
import { formatTime } from '@bihu/common-js'
import { useNavigate, useSearchParams } from 'react-router-dom'

interface Props {}

const InlinePage: React.FC<Props> = () => {

  const navigate = useNavigate()
  const [search] = useSearchParams()
  const [title, setTitle] = useState<string>('有钱来')
  const [url, setUrl] = useState<string>()

  // 返回
  const back = () => {
    sessionStorage.setItem('NeedDetainment', 'true')
    navigate(-1)
  }


  useEffect(() => {
    const urlTemp = search.get('url')
    if (urlTemp) {
      setUrl(JSON.parse(decodeURIComponent(urlTemp)))
    }
    const titleTemp = search.get('productName')
    if (titleTemp) {
      setTitle(titleTemp)
    }
  }, [])

  return (
    <div className={styles.inlinePage}>
      <Header title={title} backCallBack={back} />
      <iframe className={styles.iframe} src={url} title={title} id="iframe" name="iframe" />
    </div>
  )
}

export default InlinePage
