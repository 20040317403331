// 产品列表
import React from 'react'
import styles from './product-list.module.scss'
import Card from './components/card/card'
import Empty from '@/components/empty/empty'
import { InfiniteScroll } from 'antd-mobile'
import { ProductListResponse } from '@/apis/product'
import { EntranceType } from '@/types/apis/index'

interface Props {

  /** 数据列表 */
  listData: ProductListResponse[],

  /** 入口类型，1-首页中插，2-首页弹窗、3-开屏、4-顶部推荐、5-首页列表、6-产品列表 7-随机推荐 */
  entranceType?: EntranceType,

  /** 是否显示空数据状态 */
  isShowEmpty?: boolean

  loadMore: (isRetry: boolean) => Promise<void>

  hasMore: boolean

  /** 回调函数 */
  callBack?: ((params: any) => void) | undefined
}

const ProductList: React.FC<Props> = props => {
  const { listData, entranceType, isShowEmpty = true, loadMore, hasMore, callBack } = props
  console.log(listData)

  return (
    <div className={styles.productList}>
      {
        !!listData?.length && listData.map(item => {
          return <Card entranceType={entranceType} key={item.id} data={item} callBack={() => callBack && callBack(item)}/>
        })
      }

      {
        isShowEmpty && !listData.length && <Empty className={styles.empty} />
      }

      {
        !!listData.length && <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasMore} />
      }
    </div>
  )
}

export default ProductList
