/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from 'react'
import styles from './download.module.scss'
import { isIOS, isInWeChat, isMobile, download } from '@bihu/common-js'
import classnames from 'classnames'
import Icon from '@/components/icon/icon'
import arrow from '@imgs/download/arrows.svg'
import ios1 from '@imgs/download/ios1.png'
import ios2 from '@imgs/download/ios2.png'
import ios3 from '@imgs/download/ios3.png'
import ios4 from '@imgs/download/ios4.png'
import xiaomi1 from '@imgs/download/xiaomi1.png'
import xiaomi2 from '@imgs/download/xiaomi2.png'
import xiaomi1Youqianlai from '@imgs/download/xiaomi1-youqianlai.png'
import xiaomi2Youqianlai from '@imgs/download/xiaomi2-youqianlai.png'
import hauwei1 from '@imgs/download/huawei1.png'
import hauwei2 from '@imgs/download/huawei2.png'
import activityApi from '@/apis/activity'
import getHostNameObj from '@/hooks/get-host-name-obj'

// import { Toast } from 'antd-mobile'

interface Props{}
// 下载页面
const Download:React.FC<Props> = () => {
  const hostNameObj = getHostNameObj()
  // 是否打开提示
  const [isShowTips] = useState<boolean>(isInWeChat)
  // 当前机型
  const [currentType, setCurrentType] = useState<string>('小米')
  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)

  // 安卓下载链接
  const [androidUrl, setAndroidUrl] = useState('')
  // ios下载链接
  const [iosUrl, setIosUrl] = useState('')

  useEffect(() => {
    // 获取app下载url
    async function getAppDownloadUrl() {
      try {
        const res = await activityApi.getNewestVersion({
          appType: hostNameObj.appType
        })
        setAndroidUrl(res.androidPackageLocation)
        setIosUrl(res.iosPackageLocation)
      } catch (error) {
        // Toast.show({
        //   content: JSON.stringify(error),
        //   duration: 3000
        // })
      }
    }
    getAppDownloadUrl()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 倒计时
  useEffect(() => {
    if (isMobile && !isInWeChat) {
      if (countdownTime > 0) {
        setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      } else {
        downloadApp()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  // 下载文件
  const downloadApp = async() => {

    if (countdownTime > 0) {
      return
    }

    // 上报用户下载APP
    try {
      await activityApi.reportUserDownloadApp()
    } catch (error) {
      // console.log('error')
    }

    if (isIOS) {
      // download('http://yuanxin-app.oss-cn-shenzhen.aliyuncs.com/app_download/%E6%96%B0%E7%89%88%E5%85%83%E4%BF%A1%E9%92%B1%E5%8C%85.ipa', '元信', 'ipa')

      window.location.href = iosUrl

    } else {
      download(androidUrl, hostNameObj.appName, 'apk')
    }

  }

  // 是否展开
  const [isExpansion, setIsExpansion] = useState<boolean>(false)
  // 点击展开
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clickExpansion = (event:any) => {
    event.stopPropagation()
    setIsExpansion(!isExpansion)
  }

  // 机型数组
  const modelArr = ['小米', '华为']

  // ios的安装方法/步骤
  const iosStep = <div>
    <div className={styles.row}>
      <div className={styles.left}>
        <div className={styles.num}>1</div>
        <div className={styles.text}>
            前往<span className={styles.bold}>“设置” &gt; “通用”</span>
        </div>
      </div>
      <div className={styles.right}>
        <img src={ios1} alt="ios1" className={styles.img} />
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.left}>
        <img src={ios2} alt="ios2" className={styles.img} />
      </div>
      <div className={styles.right}>
        <div className={styles.num}>2</div>
        <div className={styles.text}>
            找到<span className={styles.bold}>“设备管理”</span>后点击进入
        </div>
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.left}>
        <div className={styles.num}>3</div>
        <div className={styles.text}>
            选择要信任的企业级应用后，点击<span className={styles.bold}>“信任”</span>，返回手机桌面，即可正常打开使用。
        </div>
      </div>
      <div className={styles.right}>
        <img src={ios3} alt="ios3" className={styles.img} />
      </div>
    </div>
    <div className={styles.row}>
      <div className={styles.right}>
        <img src={ios4} alt="ios4" className={styles.img} />
      </div>
    </div>
  </div>

  // 安卓的安装方法/步骤
  const androidStep = <div>
    {
      currentType === '小米' && <>
        <div className={styles.row}>
          <div className={styles.left}>
            <div className={styles.num}>1</div>
            <div className={styles.text}>
                    点击下载的安装包 ，来到安装页面 ，点击<span className={styles.bold}>单次授权安装</span>
            </div>
          </div>
          <div className={styles.right}>
            <img src={hostNameObj.appName === '有钱钱包' ? xiaomi1Youqianlai : xiaomi1} alt="xiaomi1" className={styles.img} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>
            <img src={hostNameObj.appName === '有钱钱包' ? xiaomi2Youqianlai : xiaomi2} alt="xiaomi2" className={styles.img} />
          </div>
          <div className={styles.right}>
            <div className={styles.num}>2</div>
            <div className={styles.text}>
              点击单次授权安装按钮，并<span className={styles.bold}>二次确认</span>后点击进入
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>
            <div className={styles.num}>3</div>
            <div className={styles.text}>验证指纹/密码，等待安装完成即可使用</div>
          </div>
        </div>
      </>
    }

    {
      currentType === '华为' && <>
        <div className={styles.row}>
          <div className={styles.left}>
            <div className={styles.num}>1</div>
            <div className={styles.text}>
            点击下载的安装包 ，并<span className={styles.bold}>允许浏览器安装应用</span>
            </div>
          </div>
          <div className={styles.right}>
            <img src={hauwei1} alt="hauwei1" className={styles.img} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>
            <img src={hauwei2} alt="hauwei2" className={styles.img} />
          </div>
          <div className={styles.right}>
            <div className={styles.num}>2</div>
            <div className={styles.text}>
            勾选<span className={styles.bold}>“已了解此应用未经检测，可能存在风险”</span>，并点击继续安装
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.left}>
            <div className={styles.num}>3</div>
            <div className={styles.text}>等待安装完成即可使用</div>
          </div>
        </div>
      </>
    }

  </div>

  return <div className={styles.downloadPage} onClick={() => setIsExpansion(false)}>
    {
      isMobile ? <>
        <div className={styles.top}>
          <div className={styles.logoBox}>
            <img className={styles.logo} src={hostNameObj.logo} alt="logo" />
            <div>
              <div className={styles.name}>
                {hostNameObj.appName}.{isIOS ? 'ipa' : 'apk'}
              </div>
              <div className={styles.size}>
                文件大小：{isIOS ? '29' : '37.9'}M
                <span className={styles.tag}>安全</span>
              </div>
            </div>
          </div>
          <div className={classnames(styles.downloadBtn, countdownTime > 0 && styles.disable)} onClick={downloadApp}>
            {
              countdownTime > 0 ? `${countdownTime}s 后将自动下载` : '点击下载'
            }
          </div>
          <div className={styles.text}>
            * 点击安装后，可回到桌面查看
            {/* {
              isIOS ? '* 点击安装后，可回到桌面查看' : '* 您可在下方切换机型，查看对应安装步骤'
            } */}
          </div>
        </div>

        {/* <div className={styles.installMethodBox}>
          <div className={styles.title}>
          安装方法/步骤
            {

              !isIOS && <div className={styles.right}>
                <div className={styles.label}>您的机型</div>
                <div className={styles.dropdownBox}>
                  <div className={styles.dropdown} onClick={clickExpansion}>{currentType} <i className={styles.arrows}></i></div>
                  <ul className={classnames(styles.selectBox, isExpansion && styles.expansion)}
                    onClick={event => event.stopPropagation()}>
                    {
                      modelArr.map(item => {
                        return <li
                          key={item}
                          className={classnames(styles.item, item === currentType && styles.active)}
                          onClick={() => {
                            setCurrentType(item)
                            setIsExpansion(false)
                          }}
                        >
                          {item}
                        </li>
                      })
                    }
                  </ul>
                </div>
              </div>
            }
          </div>
          <div className={styles.stepBox}>
            {
              isIOS ? iosStep : androidStep
            }
          </div>
        </div> */}
      </> : <div className={classnames(styles.pcTip)}>
        <Icon type="icon-warn" className={classnames(styles.icon, styles.ignoreConvert)} />
        <div className={classnames(styles.text, styles.ignoreConvert)}>请在手机浏览器上访问此链接</div>
      </div>
    }

    {
      isShowTips ? <div className={styles.tips}>
        <img className={styles.arrows} src={arrow} alt="arrow" />
        <div className={styles.tipsTitle} >
          <p>请点击右上角的</p>
          <p>选择“在浏览器打开”</p>
        </div>
      </div> : null
    }
  </div>
}

export default Download