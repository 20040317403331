/* eslint-disable no-magic-numbers */
// 个人资料
import Header from '@/components/header/header'
import React, { useEffect, useState } from 'react'
import styles from './personal-data.module.scss'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'
import {
  sexOptionMap,
  educationOptionsMap,
  realEstateOptionsMap,
  carProductionOptionsMap,
  socialSecurityOptionsMap,
  accumulationFundOptionsMap,
  insurancePolicyOptionsMap,
  professionalIdentityOptionsMap,
  enterpriseYearOptionsMap,
  workTimeOptionsMap,
  salaryOptionsMap,
  monthlyIncomeOptionsMap,
  creditConditionsOptionsMap,
  creditCardOptionsMap,
  creditSesameOptionsMap,
  applyCreditOptionsMap
} from '@/pages/fill-information/options'

interface Props {}

const PersonalData: React.FC<Props> = () => {

  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)

  const [info, setInfo] = useState<{label: string, value: string|number}[]>()

  useEffect(() => {
    if (userInfo) {
      console.log('userInfo', userInfo)
      const {
        workCity,
        realName,
        identity,
        age,
        gender,
        educationLevel,
        houseProperty,
        carProperty,
        socialSecurity,
        housingFund,
        insurance,
        job,
        licenseDuration,
        workDuration,
        payoffForm,
        monthlyProfit,
        creditInvestigation,
        creditCard,
        sesameCredit,
        applyLimit
      } = userInfo

      let arr = [
        {
          label: '工作城市',
          value: workCity
        },
        {
          label: '真实姓名',
          value: realName
        },
        {
          label: '身份证号',
          value: identity
        },
        {
          label: '年龄',
          value: age
        },
        {
          label: '性别',
          value: sexOptionMap[gender]
        },
        {
          label: '学历',
          value: educationOptionsMap[educationLevel]
        },
        {
          label: '房产类型',
          value: realEstateOptionsMap[houseProperty]
        },
        {
          label: '车产类型',
          value: carProductionOptionsMap[carProperty]
        },
        {
          label: '社保',
          value: socialSecurityOptionsMap[socialSecurity]
        },
        {
          label: '公积金',
          value: accumulationFundOptionsMap[housingFund]
        },
        {
          label: '保险保单',
          value: insurancePolicyOptionsMap[insurance]
        },
        {
          label: '职业类型',
          value: professionalIdentityOptionsMap[job]
        },

      ]

      if (job === 1) { // 上班族
        arr.push({
          label: '工作时间',
          value: workTimeOptionsMap[workDuration]
        },
        {
          label: '工资发放',
          value: salaryOptionsMap[payoffForm]
        })
      } else if (job === 3) { // 企业主
        arr.push({
          label: '企业年限',
          value: enterpriseYearOptionsMap[licenseDuration]
        })
      }

      arr = arr.concat([
        {
          label: '月收入',
          value: monthlyIncomeOptionsMap[monthlyProfit]
        },
        {
          label: '信用情况',
          value: creditConditionsOptionsMap[creditInvestigation]
        },
        {
          label: '信用卡额度',
          value: creditCardOptionsMap[creditCard]
        },
        {
          label: '芝麻分',
          value: creditSesameOptionsMap[sesameCredit]
        },
        {
          label: '申请额度',
          value: applyCreditOptionsMap[applyLimit]
        }
      ])

      setInfo(arr)
    }
  }, [userInfo])

  return (
    <div className={styles.personalData}>
      <Header title="个人资料" />
      <main className={styles.main}>
        {
          info?.map(item => {
            return <div key={item.label} className={styles.row}>
              <span>{item.label}</span>
              <span>{item.value}</span>
            </div>
          })
        }
      </main>
    </div>
  )
}

export default PersonalData
