import React, { FC, useEffect, useState, useRef } from 'react'
import { Input, Toast } from 'antd-mobile'
import styles from './apply_auth_photo.module.scss'
import classNames from 'classnames'
import Header from '@/components/header/header'
import { useNavigate } from 'react-router-dom'
interface Props {}

const ApplyHome: React.FC<Props> = () => {
  const navigate = useNavigate()
  // input 元素的 ref
  const inputRef = useRef<HTMLInputElement>(null)
  const inputRef2 = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    inputRef.current?.click()
  }
  const handleClick2 = () => {
    inputRef2.current?.click()
  }

  const [previewUrl1, setPreviewUrl1] = useState<any>(null) // 添加一个状态来保存预览的URL
  const [previewUrl2, setPreviewUrl2] = useState<any>(null) // 添加一个状态来保存预览的URL

  const handleImageChange = (e:any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setPreviewUrl1(URL.createObjectURL(file)) // 创建预览URL
    }
  }

  const handleImageChange2 = (e:any) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setPreviewUrl2(URL.createObjectURL(file)) // 创建预览URL
    }
  }

  const toNextPage = () => {
    if (previewUrl1 && previewUrl2) {
      navigate('/apply_result')
    } else {
      Toast.show('请上传身份证')
    }
  }

  return (
    <div className={styles.fullPage}>
      <Header title="实名认证" />
      <div className={styles.title}>上传本人身份证照片</div>
      <div className={styles.uploadBox}>
        <div className={styles.uploadItem} onClick={handleClick}>
          {
            previewUrl1 ? <>
              <img className={styles.applyBoxImg} src={previewUrl1} />
            </> : <>
              <img className={styles.applyBoxImg} src={require('@imgs/apply/id_front_view.png')} />
            </>
          }
          <div className={styles.applyBoxText}>点击上传<span className={styles.applyBoxTextActive}>人像面</span></div>
        </div>
        <div className={styles.uploadItem} onClick={handleClick2}>
          {
            previewUrl2 ? <>
              <img className={styles.applyBoxImg} src={previewUrl2} />
            </> : <>
              <img className={styles.applyBoxImg} src={require('@imgs/apply/id_btn_reverse_side.png')} />
            </>
          }
          <div className={styles.applyBoxText}>点击上传<span className={styles.applyBoxTextActive}>国徽面</span></div>
        </div>
      </div>
      <div className={styles.ruleBox}>
        <div className={styles.ruleBoxTitle}>上传照片要求</div>
        <img className={styles.applyBoxImg} src={require('@imgs/apply/upload-rules.png')} />
      </div>
      <div className={styles.footerBox}>
        <div className={styles.tipText}>信用仅用做审批借款额度，将加密保障您的信息安全</div>
        <div className={classNames(styles.footerBtn)} onClick={toNextPage}>下一步</div>
      </div>

      <input
        ref={inputRef}
        type="file"
        className={styles.imgUploadInput}
        onChange={handleImageChange}
      />
      <input
        ref={inputRef2}
        type="file"
        className={styles.imgUploadInput}
        onChange={handleImageChange2}
      />
    </div>
  )
}

export default ApplyHome
