// 安全中心
import React, { useState, useEffect } from 'react'
import Header from '@/components/header/header'
import styles from './safe-center.module.scss'
import { List, Modal, Button } from 'antd-mobile'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
interface Props {}

const ApplicationRecord: React.FC<Props> = () => {
  const navigate = useNavigate()
  const goto = (url:string) => {
    navigate(`/my/${url}`)
  }

  return (
    <div className={styles.applicationRecord}>
      <Header title="安全中心" />
      <List style={{
        '--padding-left': '16px',
        '--padding-right': '16px',
        '--border-bottom': 'none',
        '--font-size': '14px',
        '--prefix-width': '26px'
      }}>
        <List.Item onClick={() => goto('cancel-account')}>
          <div className={styles.itemTitle}>注销账号</div>
          <div className={styles.itemText}>提交申请，删除所有数据，注销帐号</div>
        </List.Item>
      </List>
    </div>
  )
}

export default ApplicationRecord
