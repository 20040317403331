// 借款
import React, { useEffect, useState, useRef } from 'react'
import styles from './borrow-money.module.scss'
import { Tabs, PullToRefresh } from 'antd-mobile'
import ProductList from '@/components/product-list/product-list'
import productApi, { ProductListResponse, ProductListParams } from '@/apis/product'
import { ENTRANCE_TYPE } from '@/constants/common'
import Header from '@/components/header/header'
import DetainmentPopup, { DetainmentPopupProps } from '@/components/detainment-popup/detainment-popup'
import indexApi from '@/apis'
import JointLoginPopup from '@/components/joint-login-popup/joint-login-popup'
import { useNavigate } from 'react-router-dom'

interface Props {}

const BorrowMoney: React.FC<Props> = () => {
  const navigate = useNavigate()
  const detainmentPopupRef = useRef<DetainmentPopupProps>(null)
  const JointLoginModalRef: any = useRef()

  // 下拉分页标识，上次列表最后一个产品id
  const [lastProductId, setLastProductId] = useState<number>()
  // 下拉分页标识，上次列表最后一个产品权重
  const [lastShowHomePageWeight, setLastShowHomePageWeight] = useState<number>()

  // 热门推荐产品列表
  const [hotProductList, setHotProductList] = useState<ProductListResponse[]>([])
  // 获取热门推荐产品列表
  const productListFn = async(body?:ProductListParams) => {
    const res = await productApi.productList({
      ...body,
      appH5: 4
    })

    if (res?.length) {
      setHotProductList(body?.lastProductId ? hotProductList.concat(res) : res)
      setLastProductId(res[res?.length - 1].id)
      setLastShowHomePageWeight(res[res?.length - 1].showHomePageWeight)
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }

  useEffect(() => {
    productListFn()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 下拉刷新回调
  const doRefresh = async() => {
    // 获取热门推荐产品列表
    await productListFn()
  }

  // 是否有更多
  const [hasMore, setHasMore] = useState(true)
  // 触发更多回调
  async function loadMore() {
    console.log('触发更多回调')
    await productListFn({
      lastProductId,
      lastShowHomePageWeight
    })
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // 当页面变为可见时执行的操作
        if (sessionStorage.getItem('NeedDetainment')) {
          detainmentPopupRef.current?.close()
          setTimeout(() => {
            sessionStorage.removeItem('NeedDetainment')
            detainmentPopupRef.current?.show()
          }, 20)
        }
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const callBack = (e:any) => {
    applyProduct(e, ENTRANCE_TYPE.LIST_OF_PRODUCTS)
  }

  const applyProduct = async(item:any, entranceType:any) => {
    let res:any = await indexApi.applyFor({
      productId: item.id,
      entranceType,
      appH5: 4
    })
    if (res?.unionLoginFlag === 1) {
      const params = {
        productId: item.id,
        productName: item.productName,
        productLogo: item.productLogo,
        h5Protocols: res.h5Protocols,
        loansLimitMax: item.loansLimitMax,
        entranceType,
        appH5: 4
      }
      // 打开产品联登授权弹窗
      JointLoginModalRef.current?.init(params)
      return
    }
    const isHttpsUrl = isHttpsUsingURL(res.thirdpartyTargetUrl)
    if (isHttpsUrl) {
      navigate(`/inline-page?url=${encodeURIComponent(JSON.stringify(res.thirdpartyTargetUrl))}${item.productName ? `&productName=${item.productName}` : ''}`)
    } else {
      window.location.href = res.thirdpartyTargetUrl
    }
  }

  const isHttpsUsingURL = (url:string) => {
    const match = url.match(/^(https?):\/\//)
    if (match && match[1] === 'https') {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={styles.borrowMoney}>
      <Header title={'为你推荐'} showHeaderBack={false}/>

      <div className={styles.content}>
        <PullToRefresh
          onRefresh={doRefresh}
        >
          <ProductList listData={hotProductList} entranceType={ENTRANCE_TYPE.LIST_OF_PRODUCTS} loadMore={loadMore} hasMore={hasMore} callBack={callBack} />
        </PullToRefresh>
      </div>
      <DetainmentPopup
        ref={detainmentPopupRef}
        callBack={(product, entranceType) => applyProduct(product, entranceType)}
      />
      <JointLoginPopup ref={JointLoginModalRef}></JointLoginPopup>
    </div>
  )
}

export default BorrowMoney
