import React from 'react'
import styles from './apply_tabbar.module.scss'
import { TabBar } from 'antd-mobile'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from '@/components/icon/icon'

interface Props {}

const ApplyTabbar: React.FC<Props> = props => {
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location

  const setRouteActive = (value: string) => {
    navigate(value)
  }

  const tabs = [
    {
      key: '/apply_home',
      title: '首页',
      icon: <Icon type="home" />,
    },
    {
      key: '/apply_my',
      title: '我的',
      icon: <Icon type="my" />,
    },
  ]
  return (
    <div className={styles.mainLayout}>
      <footer className={styles.footer}>
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
          {tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </footer>
    </div>
  )
}

export default ApplyTabbar
