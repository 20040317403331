// 申请记录
import React, { useState, useEffect } from 'react'
import Header from '@/components/header/header'
import styles from './application-record.module.scss'
import ProductList from '@/components/product-list/product-list'
import Empty from '@/components/empty/empty'
import productApi, { ProductListResponse } from '@/apis/product'
interface Props {}

const ApplicationRecord: React.FC<Props> = () => {

  // 热门推荐产品列表
  const [hotProductList, setHotProductList] = useState<ProductListResponse[]>([])
  // 获取热门推荐产品列表
  const productListFn = async() => {

    const res = await productApi.listTodayApplyProduct()

    setHotProductList(hotProductList.concat(res))
    console.log(res)


    // if (res.length) {
    //   setHasMore(true)
    // } else {
    //   setHasMore(false)
    // }
  }


  useEffect(() => {
    productListFn()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // // 下拉刷新回调
  // const doRefresh = async() => {
  //   // 获取热门推荐产品列表
  //   await productListFn()
  // }


  // 是否有更多
  const [hasMore, setHasMore] = useState(false)
  // 触发更多回调
  async function loadMore() {
    console.log('触发更多回调')
    await productListFn()
  }

  return (
    <div className={styles.applicationRecord}>
      <Header title="申请记录" />
      <div className={styles.productListBox}>
        <ProductList listData={hotProductList} loadMore={loadMore} hasMore={hasMore} isShowEmpty={false} />
      </div>
      {
        !hotProductList.length && <Empty text="暂无申请记录" className={styles.empty} />
      }
    </div>
  )
}

export default ApplicationRecord
