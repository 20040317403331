import React, { useEffect, useState } from 'react'
import styles from './product-item.module.scss'
import { Button } from 'antd-mobile'
import classNames from 'classnames'

interface Props{
  pruductDetail: any,
  mark?: string
}

const productItem:React.FC<Props> = props => {
  const { pruductDetail, mark } = props
  return <div className={styles.productItem}>
    <div className={styles.productTitleBox}>
      <img className={styles.productLogo} src={pruductDetail.productLogo} alt="productLogo" />
      <span className={styles.productName}>{ pruductDetail.productName }</span>
    </div>
    <div className={classNames(styles.productLimit, mark === 'HOT' && styles.hotProductLimit)}>{ pruductDetail.loansLimitMax }</div>
    {
      mark === 'HOT' ? <div className={styles.applyRateBox}>
        <div className={styles.applyRate}>已申请{ pruductDetail.applyRate }%</div>
        <div className={styles.applyRateBar}>
          <div className={styles.applyRateBarActive} style={{ width: `${pruductDetail.applyRate}%` }}></div>
        </div>
      </div> : <div className={styles.productLimitBox}>
        <div className={styles.productLimitDesc}>{'最高可借(元)'}</div>
      </div>
    }
    <Button
      className={styles.applyBtn}
      shape="rounded"
      color="primary"
    >
      立即申请
    </Button>
  </div>
}

export default productItem