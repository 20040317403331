import React, { useEffect, useState } from 'react'
import styles from './apply_home.module.scss'
import { NoticeBar, Space, Toast } from 'antd-mobile'
import indexApi from '@/apis'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { logout, selectUserInfo } from '@/store/user'
import { isIOS, Storage } from '@bihu/common-js'
import ApplyTabbar from '../components/apply_tabbar/apply_tabbar'
import Header from '@/components/header/header'
import { useNavigate } from 'react-router-dom'
interface Props {}

const ApplyHome: React.FC<Props> = () => {
  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)
  const navigate = useNavigate()
  const toNextPage = () => {
    if (Storage.get(`${userInfo?.mobile}-HasYqzsApplyAuth`)) {
      return Toast.show('抱歉，经综合评估您暂时无法申请')
    }
    navigate('/apply_limit')
  }

  return (
    <div className={styles.fullPage}>
      <Header title="有钱助手" showHeaderBack={false}/>
      <NoticeBar className={styles.noticeBar} content={'温馨提示：谨防电信网络诈骗，保护好个人信息，贷款、购物退款联系官方客服，以“退款、刷流水、保证金、解冻费”等名义先接纳各种费用都属于诈骗，请注意防范，不要添加陌生人微信，不要向陌生人转账。'} />
      <div className={styles.applyBox} onClick={toNextPage}>
        <img className={styles.applyBoxImg} src={require('@imgs/apply/home_special_header_card.png')} />
      </div>
      <div className={styles.applySteps}>
        <img className={styles.applyStepsImg} src={require('@imgs/apply/apply_steps.png')} />
      </div>
      <div className={styles.aboutUs}>
        <div className={styles.aboutUsTitle}>关于我们</div>
        <img className={styles.aboutUsImg} src={require('@imgs/apply/about_us.png')} />
      </div>
      <div className={styles.tipBox}>
        <div className={styles.tip}>请根据个人能力合理贷款，理性消费，避免逾期</div>
        <div className={styles.tip}>所有贷款申请在未成功贷款前绝不收取任何费用，为了保证您的</div>
        <div className={styles.tip}>资金安全，请不要相信任何要求您支付费用的信息、</div>
        <div className={styles.tip}>邮件、电话等不实信息。</div>
        <div className={styles.tip}>粤ICP备16075822号-1</div>
      </div>
      <ApplyTabbar />
    </div>
  )
}

export default ApplyHome
