/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
// 注册
import React, { useEffect, useState, useRef, useMemo } from 'react'
import styles from './register.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import activityApi, { ChannelDetailResponse, H5ConfigParams } from '@/apis/activity'
import indexApi, { DirectMatchInfo, GetProductInfoWidthStatus } from '@/apis'
import fingerprintjs from '@fingerprintjs/fingerprintjs'
import Skin6 from './components/skin6/skin6'
import SkinDefalut from './components/skinDefault/skinDefalut'
import Skin8 from './components/skin8/skin8'
import Skin9 from './components/skin9/skin9'
import Skin11 from './components/skin11/skin11'
import Skin12 from './components/skin12/skin12'
import Skin13 from './components/skin13/skin13'
import Skin14 from './components/skin14/skin14.'
import Skin15 from './components/skin15/skin15'
import Skin17 from './components/skin17/skin17'
import { Storage } from '@bihu/common-js'
import { CURRENT_SKIN_INDEX, TOKEN } from '@/constants/storage'
import useFirstOrAfter from '@/hooks/use-first-or-after'
import LoginPopup from './components/login-popup/login-popup'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import Skin16 from './components/skin16/skin16'
import { Toast } from 'antd-mobile'
import { useSensors } from '@/hooks/use-sensors'
import { DirectMatchStatus } from '@/constants/user'
import RegisterLoadingPage from './components/register-loading-page/register-loading-page'
import Skin18 from './components/skin18/skin18'
import { FqlPhoneModel } from '@/constants/common'
import { retry } from '@/utils/utils'
interface Props {
  type?: 'getQuota', // 领取额度
}

const adChannelType = {
  register: 1,
  getQuota: 2
}

// 跳转到结果页携带参数
export interface JumpToSuccessPagesParams {
  isNotFormReq?: boolean,
  rePageShowType?: number,
  revisit?:boolean
}

// 跳转到其他页面携带参数
export interface JumpToNextPagesParams extends JumpToSuccessPagesParams {
  adChannelCode?: string,
  isHuaWeiPhone?: boolean,
  applyLimit?: number,
  workCity?: string,
  rqResult?:GetProductInfoWidthStatus
}


const Register: React.FC<Props> = props => {
  const [search] = useSearchParams()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const navigate = useNavigate()
  const { type } = props
  // 当前皮肤号
  const [currentSkinIndex, setCurrentSkinIndex] = useState<number>(3)
  const { loginSensors, visitLoadingPageSensors, userClickLoginSensors } = useSensors()

  // 是否显示弹窗
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false)
  const [isSkin6, setIsSkin6] = useState<boolean>(false)
  const [isSkin8, setIsSkin8] = useState<boolean>(false)
  const [isSkin9, setIsSkin9] = useState<boolean>(false)
  const [isSkin11, setIsSkin11] = useState<boolean>(false)
  const [isSkin12, setIsSkin12] = useState<boolean>(false)
  const [isSkin13, setIsSkin13] = useState<boolean>(false)
  const [isSkin14, setIsSkin14] = useState<boolean>(false)
  const [isSkin15, setIsSkin15] = useState<boolean>(false)
  const [isSkin16, setIsSkin16] = useState<boolean>(false)
  const [isSkin17, setIsSkin17] = useState<boolean>(false)
  const [isSkin18, setIsSkin18] = useState<boolean>(false)
  const [showSkinDefalutPage, setShowSkinDefalutPage] = useState<boolean>(false)
  const [showPage, setShowPage] = useState<boolean>(false)
  const [codeDetail, setCodeDetail] = useState<ChannelDetailResponse>()
  //H5配置项
  const [H5Configs, setH5Configs] = useState<H5ConfigParams>()

  // 生成随机数范围
  const randomNum = (minNum:number, maxNum:number) => {
    return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
  }

  const originalPushState = window.history.pushState
  // type myPushState = typeof window.history.pushState
  // eslint-disable-next-line no-shadow
  window.history.pushState = function myPushState(...args) {
    // console.log('pushState', args, window.history.state)
    return originalPushState.apply(this, args)
  }

  useFirstOrAfter(() => {
    const skinIndex = Storage.get(CURRENT_SKIN_INDEX)
    // 有缓存的皮肤index，则+1
    if (skinIndex) {
      let temp = skinIndex + 1
      if (temp > 4) {
        temp = 1
      }

      setCurrentSkinIndex(temp)
      Storage.set(CURRENT_SKIN_INDEX, temp)
    } else { // 没有缓存皮肤index, 则随机
      let tempRandomNum = randomNum(1, 4)
      setCurrentSkinIndex(tempRandomNum)
      Storage.set(CURRENT_SKIN_INDEX, tempRandomNum)
    }
    localStorage.removeItem('productBack')
    // 判断是否分期乐渠道
  }, () => {
  // eslint-disable-next-line no-console
    console.log('afterFn')
  }, [])


  // 跳转表单填写页面
  const judgeLoginAfterPage = () => {
    if (channelValue?.includes('fenqile')) {
      navigate(`/new-fill-information?adChannelCode=${adChannelCode}`) //分期乐渠道访问流程2表单填写
      return
    }
    if (channelValue.includes('formprocess3')) {
      navigate(`/fill-information-three/loading?adChannelCode=${adChannelCode}`) //快手渠道访问流程3表单填写
      return
    }
    if (type === 'getQuota' && adChannelCode !== 'YxNj9v') {
      navigate(`/fill-information?adChannelCode=${adChannelCode}`) //如果访问链接是getQuota类型，就跳转表单填写页
    } else {
      navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({ isNotFormReq: true }))}`)
    }
  }


  // 有token状态下，判断下一页的跳转
  const judgeNextPage = async() => {
    // 如果是注册-下载模式，直接跳转下载页
    if (codeDetail?.linkType === adChannelType.register) {
      Toast.clear()
      navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({
        isNotFormReq: true,
        isSpecialListCity: true
      }))}`)
      return
    }
    // 获取表单填写状态  submitStatus >>> 0:未提交 1:已提交未授权 10:已授权
    const res = await indexApi.getApplyStatus()
    if (res.code === 200) {
      // 判断是否华为手机(针对个别渠道会用到，如分期乐)
      let isHuaWeiPhone = false
      const userAgent = navigator.userAgent.toLowerCase()
      if (userAgent.indexOf('huawei') !== -1) {
        isHuaWeiPhone = true
      }
      if (res.data && res.data?.submitStatus === 1) {
        let matchInfo: DirectMatchInfo = {
          adChannelCode,
          directMatchType: DirectMatchStatus.OLD_USER
        }
        const phoneModel = sessionStorage.getItem('phoneModel')
        if (phoneModel) {
          matchInfo.phoneModel = phoneModel as FqlPhoneModel
        }
        // 获取上回提交信息
        const submitResult = await indexApi.directMatchInfo(matchInfo)
        Toast.clear()
        // 如果是表单流程3，并且没有匹配到产品名称，则跳转结果页
        if (submitResult.productName === null && channelValue.includes('formprocess3')) {
          navigate(`/fill-information-three/success-page?options=${encodeURIComponent(JSON.stringify({
            isNotFormReq: true,
            rePageShowType: isHuaWeiPhone ? 2 : 0,
            revisit: true
          }))}`)
          return
        }
        if (submitResult.productName === null) {
          navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({
            isNotFormReq: true,
            rePageShowType: channelValue?.includes('fenqile') && isHuaWeiPhone ? 2 : 0,
            revisit: true
          }))}`)
          return
        }
        // 跳转授权页
        let tempOptions = {
          adChannelCode,
          isHuaWeiPhone,
          applyLimit: res.data.applyLimit,
          workCity: res.data.workCity,
          rqResult: submitResult
        }

        // 分期乐流程皮肤
        if (channelValue?.includes('fenqile')) {
          navigate(`/new-fill-information?options=${encodeURIComponent(JSON.stringify(tempOptions))}&applyLimit=${res.data.applyLimit}&isHuaWeiPhone=${isHuaWeiPhone}&adChannelCode=${adChannelCode}`)
        // 快手流程皮肤
        } else if (channelValue.includes('formprocess3')) {
          navigate(`/fill-information-three/authorize-page-three?options=${encodeURIComponent(JSON.stringify(tempOptions))}&applyLimit=${res.data.applyLimit}&isHuaWeiPhone=${isHuaWeiPhone}`)
        // 默认流程皮肤
        } else {
          navigate(`/fill-information/rematch-waiting?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
          // navigate(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(tempOptions))}&applyLimit=${res.data.applyLimit}&isHuaWeiPhone=${isHuaWeiPhone}`)
        }
      //
      } else if (res.data && res.data?.submitStatus === 10) {
        // rematchResult: 1-不需要重新匹配 2-重新匹配不到结果 3-重新匹配到结果
        // const reMatchResult = await indexApi.getFomrSubmitStatus({ adChannelCode })
        let matchInfo: DirectMatchInfo = {
          adChannelCode,
          directMatchType: DirectMatchStatus.OLD_USER
        }
        const phoneModel = sessionStorage.getItem('phoneModel')
        if (phoneModel) {
          matchInfo.phoneModel = phoneModel as FqlPhoneModel
        }
        const reMatchResult = await indexApi.directMatchInfo(matchInfo)
        Toast.clear()
        let tempOptions: JumpToNextPagesParams = {}
        // 如果重现匹配并且有结果，跳至动图页
        if (reMatchResult.rematchResult === 3) {
          // 携带授权数据跳转重新匹配结果页
          tempOptions = {
            adChannelCode,
            isHuaWeiPhone,
            applyLimit: res.data.applyLimit,
            workCity: res.data.workCity,
            rqResult: reMatchResult
          }
          if (channelValue.includes('formprocess3')) {
            navigate(`/fill-information-three/authorize-page-three?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
            return
          } else {
            // eslint-disable-next-line max-depth
            navigate(`/fill-information/rematch-waiting?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
            return
          }
        }
        // 否则取上回的结果，跳至下载页
        tempOptions = JSON.parse(JSON.stringify(reMatchResult))
        tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
        tempOptions.rePageShowType = channelValue?.includes('fenqile') && isHuaWeiPhone ? 2 : 0
        tempOptions.revisit = true // 标记为再访
        if (channelValue.includes('formprocess3')) {
          navigate(`/fill-information-three/success-page?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
        } else {
          navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
        }
      } else {
        Toast.clear()
        // 来到这说明未提交表单
        judgeLoginAfterPage()
      }
    } else {
      setShowPage(true)
    }
  }

  // 主流程
  const handleMainFlow = () => {
    // 获取平台来源
    const platFormSource = search.get('platformSource')
    if (platFormSource) {
      sessionStorage.setItem('FLATFORM_SOURCE', platFormSource)
    }

    const hasToken = Storage.get(TOKEN)
    // 判断是否有token(360渠道、拍拍贷渠道对token的判断单独放在各自对应的皮肤组件里)
    // const sepcialChannels = ['hBSfjw', 'ZoJERy', 'rWMspl', 'ZgvvGJ', 'pmeLeA']
    if (hasToken && !codeDetail?.location) { // 有token并且不需要获取定位，接着执行下一步
      judgeNextPage()
    } else if (channelValue?.includes('loadingPage1')) {
      setTimeout(() => {
        setShowPage(true)
      }, 2000)
    } else {
      setShowPage(true)
    }

    if (adChannelCode) {
      // 判断如果渠道码是NRxJgP，则使用皮肤6
      if (adChannelCode === 'NRxJgP') {
        setIsSkin6(true)
      }
      if (channelValue?.includes('skin8')) {
        setIsSkin8(true)
      }
      // 判断如果是借掌柜渠道，使用皮肤9
      if (channelValue?.includes('skin9')) {
        setIsSkin9(true)
      }
      if (channelValue?.includes('skin11')) {
        setIsSkin11(true)
      }
      // 判断是如果是聚富渠道，则使用皮肤12
      if (channelValue?.includes('skin12')) {
        // changeIcoFavicons('jufu-favicon.ico')
        setIsSkin12(true)
      }
      //  百度渠道，使用皮肤13
      if (channelValue?.includes('skin13')) {
        setIsSkin13(true)
      }
      // 如果是腾讯渠道，则使用皮肤14
      if (channelValue?.includes('skin14')) {
        setIsSkin14(true)
      }
      // 享呗金服渠道，使用皮肤15
      if (channelValue?.includes('skin15')) {
        setIsSkin15(true)
      }
      // 快手渠道，修改表单流程，使用皮肤16
      if (channelValue?.includes('skin16')) {
        setIsSkin16(true)
      }

      if (channelValue?.includes('skin17')) {
        setIsSkin17(true)
      }
      if (channelValue?.includes('skin18')) {
        setIsSkin18(true)
      }
      setTimeout(() => {
        setShowSkinDefalutPage(true)
      }, 10)
    }
  }

  const prevChannelValue = useRef<boolean | null>(false)

  // 调起位置授权
  const loginAfterLocation = () => {
    let hasHandleCopyFunction = false
    // 开始执行位置授权
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          // 获取到的地理位置 position
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(position.coords.latitude, position.coords.longitude)
        },
        error => {
          // 获取位置失败
          if (hasHandleCopyFunction) {
            return
          }
          getUserLocation(null, null)
        },
        {
          enableHighAccuracy: true, // 位置是否精确获取
          timeout: 2000, //获取位置允许的最长时间
        }
      )
    } else {
      getUserLocation(null, null)
    }
  }
  const getUserLocation = async(latitude: number | null, longitude: number | null) => {
    let params = latitude ? {
      location: `${latitude},${longitude}`,
      adChannelCode: codeDetail?.adChannelCode,
      coordtype: 'wgs84ll',
    } : {
      adChannelCode: codeDetail?.adChannelCode,
    }
    const res = await activityApi.reverseGeo(params)
    Toast.clear()
    if (res.data?.excludeCity && codeDetail?.linkType === 2) {
      await activityApi.reportDevice({
        provinceName: res.data.province,
        cityName: res.data.city,
      })
      navigate(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({
        isNotFormReq: true,
        isSpecialListCity: true
      }))}`)
    } else {
      // 如果不在白名单，报告父组件，执行正常跳转的逻辑
      judgeNextPage()
    }
  }
  // 立即注册
  const register = async(code: string, phone: string, skinNum = 360) => {
    if (!code) {
      return Toast.show('请输入验证码')
    }
    try {
      Toast.show({
        icon: 'loading',
        content: '领取中…',
        duration: 1000,
      })
      // 前端神策埋点 - 用户点击登录
      userClickLoginSensors(+phone)
      const abTestType = skinNum
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await activityApi.login({
        mobile: phone,
        code,
        adChannelCode,
        abTestType,
        isFormReq: type === 'getQuota',
      })
      // if (channelValue?.includes('bdVid')) {
      //   window._agl && window._agl.push(['track', ['success', { t: 92 }]])
      // }
      // console.log(res)
      if (res) {
        Storage.set('user_uid', res.loginUser.uid)
        loginSensors(res.loginUser.uid)
        Storage.set(TOKEN, res.token)
        if (res.loginUser?.coverStatus) {
          handlerSetcoverStatus()
          return
        }
        if (codeDetail?.location) {
          loginAfterLocation()
        } else {
          judgeNextPage()
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Toast.clear()
      Toast.show({
        content: error.msg,
        duration: 2000,
      })
      // Toast.clear()
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  // 上报浏览器指纹
  useEffect(() => {
    if (currentSkinIndex && adChannelCode && !prevChannelValue.current) {
      prevChannelValue.current = true
      // 获取到浏览器指纹后调用上报接口
      fingerprintjs.load().then(fp => fp.get())
        .then(result => {
          const { visitorId } = result
          activityApi.reportAdChannelClick({
            adChannelCode,
            fingerprint: visitorId,
            abTestType: isSkin6 ? 6 : 5 // 固定皮肤数
          // abTestType: currentSkinIndex // 随机皮肤数
          })
        })
    }
  }, [currentSkinIndex, adChannelCode])

  // 获取渠道详情，判读是否禁用后执行相应逻辑
  useEffect(() => {
    let url = window.location.href
    const channelType = new URL(url).pathname.split('/').pop()
    localStorage.setItem('ad_channel_type', channelType || '')
    const getChannelDetail = async() => {
      const codeDetailRes = await retry(() => activityApi.getChannelCodeDetail(adChannelCode))
      //2-快手、3-百度、4-soul 5-推啊
      switch (codeDetailRes.trackType) {
        case 2:
          search.get('callback') && Storage.set(`${adChannelCode}-ksCallBack`, search.get('callback'))
          break
        case 3:
          Storage.set(`${adChannelCode}-BDburyingPoint`, window.location.href)
          break
        case 4:
          search.get('callback') && Storage.set(`${adChannelCode}-soulCallBack`, search.get('callback'))
          break
        case 5:
          search.get('a_oId') && Storage.set(`${adChannelCode}-tuiaCallBack`, search.get('a_oId'))
          break
        default: break
      }
      //广告渠道状态，1-正常，0-禁用
      if (codeDetailRes && codeDetailRes.channelStatus === 0) {
        navigate('/failure')
        return
      }
      //获取H5自定义配置项
      if (codeDetailRes.isEnableH5Config === 1) {
        const res = await getH5Config(codeDetailRes.id)
        const configObj = {
          ...codeDetailRes,
          H5ConfigItem: res
        }
        setCodeDetail(configObj)
      } else {
        setCodeDetail(codeDetailRes)
      }

    }
    if (adChannelCode) {
      getChannelDetail()
    }
  }, [adChannelCode])

  const handlerSetcoverStatus = () => {
    if (channelValue.includes('fenqile')) {
      navigate(`/new-fill-information?adChannelCode=${adChannelCode}&coverStatus=1`)
    } else if (channelValue.includes('formprocess3')) {
      navigate(`/fill-information-three/loading?adChannelCode=${adChannelCode}&coverStatus=1`)
    } else {
      navigate(`/fill-information?adChannelCode=${adChannelCode}&coverStatus=1`)
    }
    Toast.clear()
  }

  const getH5Config = async(adChannelId: number) => {
    const res = await activityApi.getH5Config({ adChannelId })
    setH5Configs(res)
    return res
  }

  const renderSkinComponent = useMemo(() => {
    switch (true) {
      case isSkin6:
        return <Skin6 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      // case isSkin7:
      //   return <Skin7 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      case isSkin8:
        return <Skin8 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      case isSkin9:
        return <Skin9 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      // case isSkin10:
      //   return <Skin10 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      case isSkin11:
        return <Skin11 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      case isSkin12:
        return <Skin12 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail}/>
      case isSkin13:
        return <Skin13 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail}/>
      case isSkin14:
        return <Skin14 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      case isSkin15:
        return <Skin15 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      case isSkin16:
        return <Skin16 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      case isSkin17:
        return <Skin17 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
      case isSkin18:
        return <Skin18 loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail}></Skin18>
      default:
        return showSkinDefalutPage ? (
          <SkinDefalut loginAfterLocation={loginAfterLocation} register={register} channelDetail={codeDetail} />
        ) : null
    }
  }, [isSkin6, isSkin8, isSkin9, isSkin11, isSkin12, isSkin13, isSkin14, isSkin15, isSkin16, isSkin18, showSkinDefalutPage])

  useEffect(() => {
    if (codeDetail) {
      // 执行页面主流程
      handleMainFlow()
    }
  }, [codeDetail, channelValue])

  useEffect(() => {
    let uid = localStorage.getItem('user_uid')
    let token = localStorage.getItem('TOKEN')
    // 前端神策埋点 - 用户访问落地页
    visitLoadingPageSensors()
    // 判断，如果用户登录了并且有uid和token，可以自动登录时，就直接调用神策的登录接口进行匹配
    if (token && uid) {
      loginSensors(uid)
    }
  }, [])

  // useEffect(() => {
  //   if (channelValue.includes('jumpWeixin')) {
  //     window.location.href = 'https://ivrk.urlint.cn/caTJMu'
  //   }
  // }, [channelValue])

  const loadingPage = useMemo(() => {
    if (channelValue.includes('loadingPage1')) {
      return <RegisterLoadingPage />
    }
    if (!adChannelCode) {
      return null
    } else {
      return <div id="root">
        <div id="loading-box">
          <div className={styles.ballClipRotateMultiple}>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    }
  }, [channelValue, adChannelCode])

  return (
    <div className={styles.registerPage}>
      {
        // eslint-disable-next-line no-nested-ternary
        showPage
          ? <div>
            {renderSkinComponent}
            {
              isShowPopup && channelValue?.includes('fenqile') && <LoginPopup type={type} callBack={() => setIsShowPopup(false)}/>
            }
          </div> : loadingPage
      }
    </div>
  )
}

export default Register
