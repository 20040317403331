/* eslint-disable no-magic-numbers */
// 挽留弹窗
import React, { useRef, useImperativeHandle, useEffect, useState } from 'react'
import styles from './home-popup.module.scss'
import ResetModal, { ResetModalProps } from '@/components/reset-modal/reset-modal'
import { Button } from 'antd-mobile'
import productApi, { ProductListResponse } from '@/apis/product'
import indexApi from '@/apis'
import { yuan2wan } from '@bihu/common-js'
import { jumpInlinePage } from '@/utils/utils'
import { ENTRANCE_TYPE } from '@/constants/common'

interface Props {
  // 回调函数
  callBack?: (data: any, entranceType: any) => void
  isRetain:string | null
}

export interface HomePopupProps{
  show: () => void,
  close: () => void,
}

const DetainmentPopup = React.forwardRef<HomePopupProps, Props>((props, ref) => {
  const ResetModalRef = useRef<ResetModalProps>(null)
  const { callBack } = props

  // 首页弹窗
  const [product, setProduct] = useState<any>()
  useEffect(() => {
    const getHomeDialogBanner = async() => {
      const adList = await indexApi.getHomeDialogBanner({ appH5: props.isRetain ? 5 : 4 })
      if (adList?.length) {
        const homeProduct = findAvailableAd(adList)
        setProduct(homeProduct)
        setTimeout(() => {
          ResetModalRef.current?.show()
        }, 500)
      }
    }
    getHomeDialogBanner()
  }, [])

  // 寻找权重合适的首页广告
  const findAvailableAd = (adList:any) => {
    let adItem = {}
    // 如果只有一条数据，则直接返回
    if (adList.length === 1) {
      return adList[0]
    }
    const haveShowAdList = localStorage.getItem('home_dialog_display')
    let oldList:any = haveShowAdList ? JSON.parse(haveShowAdList) : []
    if (!oldList?.length) {
      adItem = findMaxWeightModel(adList)
    } else {
      let tempSet = neitherListExists(adList, oldList)
      if (!tempSet.length || oldList.length >= adList.length) {
        adItem = findMaxWeightModel(adList)
        oldList = []
      } else {
        adItem = findMaxWeightModel(tempSet)
      }
    }
    oldList.push(adItem)
    localStorage.setItem('home_dialog_display', JSON.stringify(oldList))
    return adItem
  }

  // 找出权重最大的广告
  const findMaxWeightModel = (models:any) => {
    let maxModel = models[0]
    for (let model of models) {
      if (model.weight > maxModel.weight) {
        maxModel = model
      }
    }
    return maxModel
  }

  // 过滤两个数组都不存在的数据
  const neitherListExists = (newList:any, oldList:any) => {
    // 定义一个函数，判断某个元素是否在另一个数组中存在
    const existsInArray = (item:any, array:any) => array.some((element:any) => element.id === item.id)

    // 筛选出只存在于第一个数组中的元素
    const uniqueToNewList = newList.filter((item:any) => !existsInArray(item, oldList))

    // 筛选出只存在于第二个数组中的元素
    const uniqueToOldList = oldList.filter((item:any) => !existsInArray(item, newList))

    // 将两组独特元素合并
    return [...uniqueToNewList, ...uniqueToOldList]
  }

  const show = () => {
    ResetModalRef.current?.show()
  }

  const close = () => {
    ResetModalRef.current?.close()
  }

  useImperativeHandle(ref, () => (
    {
      show,
      close
    }
  ))

  const goto = (cardData:ProductListResponse) => {
    callBack && callBack(cardData, ENTRANCE_TYPE.HOME_PAGE_POPUP)
  }


  return (
    <div className={styles.detainmentPopup}>
      <ResetModal ref={ResetModalRef}>
        <div className={styles.resetModalContent}>
          <div className={styles.productBox}>
            <img className={styles.productLogo} src={product?.productLogo} alt="productLogo" />
            <div className={styles.productName}>{ product?.title }</div>
            <div className={styles.productLimit}>{ product?.loansLimitMax }</div>
          </div>
          <div className={styles.footerBox}>
            <div className={styles.applyTips}>立即领取专属借款额度，今日有效</div>
            <Button block className={styles.btn} shape="rounded" color="primary" onClick={() => goto(product!)}>
              立即领取额度
            </Button>
            <div className={styles.otherBtn} onClick={close}>查看其他贷款产品</div>
          </div>
        </div>
      </ResetModal>
    </div>
  )
})

export default DetainmentPopup
