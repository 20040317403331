import React, { useEffect, useState, useRef } from 'react'
import styles from './apply_auth_face.module.scss' // 确保引入了相应的CSS文件

function FaceRecognition() {
  const videoRef:any = useRef(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } })
      .then(stream => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream
        }
      })
      .catch(err => console.error('error:', err))
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAuthenticated(true)
    }, 5000) // 加载动画持续5秒

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={styles.fullPage}>
      <div className={styles.title}>请将脸移入框内</div>
      <div className={styles.cameraWrapper}>
        <video ref={videoRef} muted autoPlay webkit-playsinline playsInline className={styles.videoElement}></video>
        <div className={styles.loadingCircle}></div>
        {isAuthenticated && <p className={styles.authenticationMessage}>认证成功, 跳转结果页2</p>}
      </div>
    </div>
  )
}

export default FaceRecognition