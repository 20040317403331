/* eslint-disable no-magic-numbers */
// 挽留弹窗
import React, { useRef, useImperativeHandle, useEffect, useState } from 'react'
import styles from './detainment-popup.module.scss'
import ResetModal, { ResetModalProps } from '@/components/reset-modal/reset-modal'
import { Button } from 'antd-mobile'
import productApi, { ProductListResponse } from '@/apis/product'
import { yuan2wan } from '@bihu/common-js'
import { jumpInlinePage } from '@/utils/utils'
import { ENTRANCE_TYPE } from '@/constants/common'

interface Props {
  // 回调函数
  callBack?: (data:any, entranceType:any) => void
}

export interface DetainmentPopupProps{
  show: () => void,
  close: () => void,
}

const DetainmentPopup = React.forwardRef<DetainmentPopupProps, Props>((props, ref) => {
  const ResetModalRef = useRef<ResetModalProps>(null)
  const { callBack } = props

  // 随机产品
  const [product, setProduct] = useState<ProductListResponse>()

  const show = () => {
    const randomRecommend = async() => {
      const res = await productApi.randomRecommend({ appH5: 4 })
      setProduct(res)
      setTimeout(() => {
        ResetModalRef.current?.show()
      }, 500)
    }
    randomRecommend()
  }

  const close = () => {
    ResetModalRef.current?.close()
  }

  useImperativeHandle(ref, () => (
    {
      show,
      close
    }
  ))

  const goto = (cardData:ProductListResponse) => {
    callBack && callBack(cardData, ENTRANCE_TYPE.RANDOM_RECOMMENDATION)
  }


  return (
    <div className={styles.detainmentPopup}>
      <ResetModal ref={ResetModalRef}>
        <div className={styles.resetModalContent}>
          <div className={styles.productBox}>
            <img className={styles.productLogo} src={product?.productLogo} alt="productLogo" />
            <div className={styles.productName}>{ product?.productNickName }</div>
            <div className={styles.productLimit}>{ product?.loansLimitMax }</div>
          </div>
          <div className={styles.footerBox}>
            <div className={styles.applyTips}>立即领取专属借款额度，今日有效</div>
            <Button block className={styles.btn} shape="rounded" color="primary" onClick={() => goto(product!)}>
              立即领取额度
            </Button>
            <div className={styles.otherBtn} onClick={close}>查看其他贷款产品</div>
          </div>
        </div>
      </ResetModal>
    </div>
  )
})

export default DetainmentPopup
