import React, { useEffect, useState, useRef } from 'react'
import styles from './apply_auth_form.module.scss' // 确保引入了相应的CSS文件
import { Form, Input, Toast } from 'antd-mobile'
import classNames from 'classnames'
import { validateIDNumber } from '@bihu/common-js'
import Header from '@/components/header/header'
import { useNavigate } from 'react-router-dom'

function AuthResult() {
  const navigate = useNavigate()
  // 真实姓名
  const [realName, setRealName] = useState('')
  // 身份证号
  const [identity, setIdentity] = useState('')

  // 检验身份证
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkIdentity = (value: string) => {
    if (value) {
      if (!validateIDNumber(value)) {
        Toast.show('身份证号不正确，请重新输入')
        return Promise.reject(new Error('身份证号不正确，请重新输入'))
      }
      return Promise.resolve()
    }
    Toast.show('请输入身份证号')
    return Promise.reject(new Error('请输入身份证号'))
  }

  const toNextPage = async() => {
    if (!realName) {
      Toast.show('请输入姓名')
      return
    }
    await checkIdentity(identity)
    navigate('/apply_auth_photo')
  }
  return (
    <div className={styles.fullPage}>
      <Header title="实名认证" />
      <div className={styles.noticeBar}>
        <img className={styles.applyBoxImg} src={require('@imgs/apply/safe.png')} />
        <span>填写个人真实信息，获取借款额度</span>
      </div>
      <div className={styles.from}>
        <div className={styles.formItem}>
          <div className={styles.formItemTitle}>姓名</div>
          <div className={styles.formItemInput}>
            <Input placeholder="请输入姓名" value={realName} onChange={val => {
              setRealName(val)
            }}/>
          </div>
        </div>
        <div className={styles.formItem}>
          <div className={styles.formItemTitle}>身份证号</div>
          <div className={styles.formItemInput}>
            <Input placeholder="请输入身份证号" value={identity} onChange={val => {
              setIdentity(val)
            }}/>
          </div>
        </div>
      </div>
      <div className={classNames(styles.footerBtn)} onClick={toNextPage}>下一步</div>
    </div>
  )
}

export default AuthResult