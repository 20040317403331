// 产品卡片
import React, { useMemo } from 'react'
import styles from './card.module.scss'
import { parseNum } from '@bihu/common-js'
import classNames from 'classnames'
import { ProductListResponse } from '@/apis/product'
import { jumpInlinePage, randomNum } from '@/utils/utils'
import { EntranceType } from '@/types/apis/index'
import { Toast } from 'antd-mobile'

interface Props {
  data: ProductListResponse
  // 入口类型，1-首页中插，2-首页弹窗、3-开屏、4-顶部推荐、5-首页列表、6-产品列表 7-随机推荐
  entranceType?: EntranceType

   /** 回调函数 */
   callBack?: () => void
}

const Card: React.FC<Props> = props => {

  const { data, entranceType, callBack } = props

  const goto = (cardData:ProductListResponse) => {
    if (callBack) {
      return callBack()
    }
  }

  const numberOfApplicants = useMemo(() => {
    return randomNum(6000, 8000)
  }, [])

  return (
    <div className={classNames(styles.card, 'productCard')} onClick={() => goto(data)}>
      <div className={styles.top}>
        <div className={styles.nameBox}>
          <img src={data.productLogo} alt={data.productName} className={styles.icon} />
          <span className={styles.name}>{data?.productNickName}</span>
          <label className={styles.tag}>月利率：{data.monthlyInterestRate}%</label>
        </div>
        <div className={styles.numberOfApplicants}>今日放款: {numberOfApplicants} 人</div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.left}>
          <div className={styles.limitBox}>
            <div className={styles.limit}>{parseNum(data.loansLimitMax)}</div>
            <div className={styles.limitText}>最高额度（元）</div>
          </div>
          <div className={styles.rateBox}>
            <div className={styles.interestRate}>
              {data.lendingRate || 53}
              <span className={styles.unit}>%</span>
            </div>
            <div className={styles.interestRateText}>放款率</div>
          </div>
        </div>
        <div className={classNames(styles.apply, data.applyLimit && styles.disable)}>立即申请</div>
      </div>
    </div>
  )
}

export default Card
