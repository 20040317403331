// 空数据
import React from 'react'
import styles from './empty.module.scss'
import empty from '@imgs/index/empty.png'
import classNames from 'classnames'

interface Props {
  text?: string
  className?: string,
}

const Empty: React.FC<Props> = props => {
  const { text = '暂无产品', className = '' } = props
  return (
    <div className={classNames(styles.empty, className)}>
      <img src={empty} alt="emptyImg" className={styles.img} />
      <div className={styles.text}>{text}</div>
    </div>
  )
}

export default Empty
