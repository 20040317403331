import Button from './components/button/button'

export default function Test() {
  return (
    <>
      <iframe style={{
        height: '100%',
        width: '100%'
      }} src="https://qa.oss.h5.yuanxinwallet.com/protocol-fill-information?protocolId=64744a0665faa56f2ba42c26&productId=121&maskName=null&maskMobile=null&workCity=重庆市,市辖区"></iframe>
    </>
  )
}