import React, { useEffect, useState, useRef } from 'react'
import styles from './apply_result.module.scss' // 确保引入了相应的CSS文件
import classNames from 'classnames'
import Header from '@/components/header/header'
import { Storage } from '@bihu/common-js'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { selectUserInfo } from '@/store/user'
import { useNavigate } from 'react-router-dom'

function AuthResult() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const callBack = () => {
    Storage.set(`${userInfo?.mobile}-HasYqzsApplyAuth`, true)
    navigate('/apply_home')
  }

  const userInfo = useAppSelector(selectUserInfo)
  return (
    <div className={styles.fullPage}>
      <Header title="实名认证" showHeaderBack={false} />
      <img className={styles.empty} src={require('@imgs/apply/empty.png')} />
      <div className={styles.authResultText}>申请失败</div>
      <div className={styles.authResultTips}>抱歉，经综合评估，您暂时不符合申请条件。</div>
      <div className={classNames(styles.footerBtn)} onClick={callBack}>返回</div>
    </div>
  )
}

export default AuthResult