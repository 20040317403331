/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styles from './success.module.scss'
import { isIOS, isInWeChat, isMobile, download, Storage } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import IosSkin from './components/ios-skin/ios-skin'
import Fenqile from './components/fenqile/fenqile'
import WhiteListCity from './components/360-white-list-city/360-white-list-city'
import MiniGrogram from './components/mini-program/mini-program'
import NormalSkin from './components/normal-skin/normal-skin'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { judgeBrand } from '@/utils/utils'
import { useAppDispatch } from '@/store/hooks'
import { logout } from '@/store/user'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'
import { useSensors } from '@/hooks/use-sensors'
import Icon from '@/components/icon/icon'

interface Props{}

const Success: React.FC<Props> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [search] = useSearchParams()
  const options: any = search.get('options')
  let isMultiProduct = search.get('isMultiProduct') === 'true'
  let resData:any = {}
  if (options) {
    try {
      resData = JSON.parse(decodeURIComponent(options))
    } catch {
      resData = JSON.parse(options)
    }
  }
  const { productName, productNickName, thirdpartyType, thirdpartyTargetUrl } = resData
  let { applyStatus } = resData //是否hold单，状态为8代表hold单
  // let { revisit } = resData // 用户已授权状态下，再次访问结果页的标识(非第一回)
  let revisit = true // 暂时去掉再访状态的来源获取，默认为true
  let isProduct = process.env.REACT_APP_API_ENV === 'production'
  const { isSpecialListCity } = resData // 是否来自特殊城市的用户 || 注册-下载模式的用户
  const { downloadAppSensors } = useSensors()

  /** rePageShowType 0/null 不处理 1：取消下载需求 2：引导公众号需求 */
  let { rePageShowType } = resData
  let xiaomiAppid = 'com.yq.wallet'
  let jkrHaierPackageName = 'com.jufu.jry'
  let jkrHaierAndroidAppUrl = 'https://sfqb-oss.jukerong.cn/app/android/app.apk'

  const [isMiniProgram, setIsMiniProgram] = useState(false)
  // 渠道详情
  const [codeDetail, setCodeDetail] = useState<any>()
  //isPageSetup 为true时 结果页无只有分发结果，无H5产品和下载
  const [isPageSetup, setIsPageSetup] = useState(false)
  // 是否已经上报结果页访问
  const isReport = useRef<boolean>(false)

  // 前端神策埋点 - 用户访问结果页
  const sensorsVisitResultPage = (pageSetup: number) => {
    if (isReport.current) {
      return
    }
    let updateInfo = {
      ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
      mobile: localStorage.getItem('user_phone') || '',
      h5_match_result: !!productName,
      result_page_monetization: !pageSetup
    }
    sensors.track(SensorsType.visit_result_page, updateInfo)
    isReport.current = true
  }

  useEffect(() => {
    const getChannelDetail = async() => {
      const codeDetailRes = await activityApi.getChannelCodeDetail(adChannelCode)
      sensorsVisitResultPage(codeDetailRes?.pageSetup)
      setCodeDetail(codeDetailRes)
      if (codeDetailRes?.pageSetup) {
        setIsPageSetup(true)
      }
    }
    if (adChannelCode) {
      getChannelDetail()
    }
    if (channelValue?.includes('baidu')) {
      rePageShowType = 2
    }
  }, [adChannelCode, channelValue])

  // app类型 1-元信花yuanxinwallet.com、2-有钱来ningkun8.cn
  const appType = useMemo(() => {
    return 1
  }, [])

  // 安卓下载链接
  const [androidUrl, setAndroidUrl] = useState('')
  // ios下载链接
  const [iosUrl, setIosUrl] = useState('')

  useEffect(() => {
    // 获取平台来源
    const platFormSource = sessionStorage.getItem('FLATFORM_SOURCE')
    if (platFormSource === 'MiniProgram') {
      setIsMiniProgram(true)
    }

    // 获取app下载url
    async function getAppDownloadUrl() {
      try {
        const res = await activityApi.getNewestVersion({
          appType
        })
        setAndroidUrl(res.androidPackageLocation)
        setIosUrl(res.iosPackageLocation)
      } catch (error) {
        // Toast.show({
        //   content: JSON.stringify(error),
        //   duration: 3000
        // })
      }
    }
    getAppDownloadUrl()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (thirdpartyType === 4 && thirdpartyTargetUrl) {
      jumpOtherUrl()
      return
    }

    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      } else if (rePageShowType !== 2 && rePageShowType !== 1 && !isIOS && !revisit) {
        downloadApp()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  // 跳转第三方
  const jumpOtherUrl = () => {
    if (thirdpartyType === 4 && thirdpartyTargetUrl) {
      // 跳转第三方链接
      window.location.href = thirdpartyTargetUrl
    }
  }

  // 判断是否是小米手机
  const isXiaomi = () => {
    const { userAgent } = navigator
    return /XiaoMi|MIUI/.test(userAgent)
  }

  // 判断是否是vivo手机
  // const isVivo = () => {
  //   const { userAgent } = navigator
  //   return userAgent.toLowerCase().includes('vivo')
  // }

  const downloadAppSubmit = async() => {
    try {
      let fillInfo = localStorage.getItem('fillInformation')
      await activityApi.reportUserDownloadApp(fillInfo ? JSON.parse(fillInfo) : {})
      // 前端神策埋点 - 用户点击下载 App
      downloadAppSensors()
      sensors.track(SensorsType.click_download_app, {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: localStorage.getItem('user_phone') || ''
      })
      // 百度渠道下载app需要上报
      // if (channelValue?.includes('bdVid')) {
      //   window._agl && window._agl.push(['track', ['success', { t: 2 }]])
      // }
    } catch (err) {
      console.log(err)
    }
  }

  // 下载文件
  const downloadApp = () => {
    // 判断是否为拍拍贷
    if (isPageSetup) {
      return
    }
    // 上报用户下载APP
    downloadAppSubmit()
    if (isIOS) {
      // download('http://yuanxin-app.oss-cn-shenzhen.aliyuncs.com/app_download/%E6%96%B0%E7%89%88%E5%85%83%E4%BF%A1%E9%92%B1%E5%8C%85.ipa', '元信', 'ipa')
      // 跳转IOS应用商店
      if (channelValue?.includes('iosDownloadyqqb')) {
        window.location.href = 'https://apps.apple.com/cn/app/%E6%9C%89%E9%92%B1%E9%92%B1%E5%8C%85/id6476535957'
        return
      }
      if (channelValue?.includes('jkrHaierDownloadApp')) {
        window.location.href = 'https://apps.apple.com/app/id6458102344'
        return
      }
      // 如果是拍拍贷则跳转到有钱钱包appstore
      window.location.href = iosUrl
    } else {
      if (adChannelCode === 'r97gwM') {
        window?.WebViewJavascriptBridge && window.WebViewJavascriptBridge?.callHandler('openExternalBrowser', { jumpUrl: androidUrl }, (res:any) => {
          Toast.show({
            content: '跳转成功',
            duration: 3000
          })
        })
      }
      if (channelValue.includes('androidDownloadyqqb')) {
        download('https://yuanxin-app.oss-cn-shenzhen.aliyuncs.com/android_package/2024/11/19/youqianqianbao-h5Release-v1.0.8_8-20241119_09_26_14.apk')
        return
      }
      // 让拍拍贷渠道单独下载指定的安卓有钱钱包
      if (channelValue?.includes('paipaidai')) {
        if (isXiaomi()) {
          // 拍拍贷小米手机下载应用商店
          window.location.href = `mimarket://details?id=${xiaomiAppid}`
          return
        }
        download('https://static.chongdong.cc/android_package/youqianqiaobao-h5Release-v1.0.9_9-20240530.apk', '有钱钱包', 'apk')
        return
      }

      //聚客融海尔下载他们自己的app
      if (channelValue?.includes('jkrHaierDownloadApp')) {
        if (judgeBrand(navigator.userAgent.toLowerCase()) === 'xiaomi') {
          window.location.href = `mimarket://details?id=${jkrHaierPackageName}`
          return
        }
        if (judgeBrand(navigator.userAgent.toLowerCase()) === 'vivo') {
          window.location.href = `vivomarket://details?id=${jkrHaierPackageName}`
          return
        }
        download(jkrHaierAndroidAppUrl, '聚融易', 'apk')
        return
      }
      download(androidUrl, '元信', 'apk')
    }
  }

  const copyDownLoadUrl = () => {
    Toast.show('链接已复制，快去浏览器打开下载吧!')
    if (isIOS) {
      copy(iosUrl)
    } else {
      copy(androidUrl)
    }
  }


  const miniComponent = () => {
    return (
      <MiniGrogram productName={productName} applyStatus={applyStatus} onChange={copyDownLoadUrl}></MiniGrogram>
    )
  }

  const backRegister = () => {
    if (channelValue.includes('backRegister')) {
      navigate(`/getQuota${adChannelCode ? `?adChannelCode=${adChannelCode}` : ''}`)
      dispatch(logout())
    }
  }

  useEffect(() => {
    const handlePopState = () => {
      if (!channelValue?.includes('mjy') && !channelValue?.includes('jufu')) {
        return
      }
      if (isProduct) {
      // Retain参数代表是从本success页面跳转到挽留页面
        navigate('https://yql-h5.chongdong.cc/advertising?retain=1')
      } else {
        window.location.href = 'https://qa.oss.h5.yuanxinwallet.com/advertising?retain=1'
      }
    }
    // 初始加载时添加一个历史条目
    // window.history.pushState(null, '', document.URL)

    // 监听popstate事件
    window.addEventListener('popstate', handlePopState)


    // 清理事件监听器
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const otherResultComponent = () => {
    return (
      <div className={styles.successPage}>
        {channelValue.includes('backRegister') && <Icon className={styles.backIcon} type="back" onClick={backRegister} />}
        {
          rePageShowType === 1 || (rePageShowType !== 2 && !isIOS && applyStatus !== 8 && !revisit && !isPageSetup && !isSpecialListCity) ? <>
            <NormalSkin
              productName={productNickName || productName}
              rePageShowType={rePageShowType}
              countdownTime={countdownTime}
              onChange={downloadApp}
              isMultiProduct={isMultiProduct}
            ></NormalSkin>
          </> : <>
            {
              rePageShowType === 2 || (isPageSetup && !isSpecialListCity) ? <>
                <Fenqile isMultiProduct={isMultiProduct} productName={productNickName || productName} applyStatus={applyStatus} ></Fenqile>
              </> : <>
                {
                  isSpecialListCity ? <>
                    <WhiteListCity onChange={downloadApp}></WhiteListCity>
                  </> : <>
                    <IosSkin isMultiProduct={isMultiProduct} productName={productNickName || productName} applyStatus={applyStatus} revisit={revisit} onChange={downloadApp}></IosSkin>
                  </>
                }
              </>
            }
          </>
        }
      </div>
    )
  }

  // 分期乐页面机型判断，如果分期乐的oppo或者vivo机型，走不通过rePageShowType字段判断，直接走ios页面，展示后端h5变现
  const fqlResultComponent = () => {
    return <IosSkin isMultiProduct={isMultiProduct} productName={productNickName || productName} applyStatus={applyStatus} revisit={revisit} onChange={downloadApp}></IosSkin>
  }

  return (
    <div>
      {isMiniProgram && rePageShowType !== 1
        ? miniComponent()
        : sessionStorage.getItem('fql_phoneModel')
          ? codeDetail && fqlResultComponent()
          : codeDetail && otherResultComponent()
      }
    </div>
  )
}

declare const window: Window & { WebViewJavascriptBridge: any, WVJBCallbacks: any }

export default Success