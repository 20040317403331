/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react'
import Header from '@/components/header/header'
import styles from './protocol-fill-information.module.scss'
import classNames from 'classnames'
import { getParams, Storage } from '@bihu/common-js'
import { useSearchParams } from 'react-router-dom'
import indexApi from '@/apis'
import { TOKEN } from '@/constants/storage'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { CompanyType } from '@/constants/common'
interface Props{}
// 上游协议回显
const ProtocolComponent:React.FC<Props> = props => {
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const [isShowHeader, setIsShowHeader] = useState(false)
  const [search] = useSearchParams()
  const [agreementContent, setAgreementContent] = useState('')
  const [agreementTitle, setAgreementTitle] = useState('')
  const [token, setToken] = useState('')

  let isFirstRun = true

  useEffect(() => {
    const title:any = search.get('title')
    setAgreementTitle(title)
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }


    let tokenTemp = Storage.get(TOKEN)
    const urlToken = search.get('token')
    if (urlToken) {
      Storage.set(TOKEN, urlToken)
      setToken(urlToken)
    } else if (tokenTemp) {
      setToken(tokenTemp)
    }
  }, [])

  useEffect(() => {
    if (!isFirstRun) {
      return
    }
    const urlParams = window.location.href
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isFirstRun = false
    const protocolId:any = search.get('protocolId') && getParams(urlParams, 'protocolId')
    const productId:any = search.get('productId') && getParams(urlParams, 'productId')
    const maskName:any = search.get('maskName') && getParams(urlParams, 'maskName')
    const maskMobile:any = search.get('maskMobile') && getParams(urlParams, 'maskMobile')
    const workCity:any = search.get('workCity') && getParams(urlParams, 'workCity')
    async function getAgreementInfo() {
      const res:any = await indexApi.getProtocolContent(protocolId, productId, maskName, maskMobile, workCity)
      if (channelValue?.includes('jfxd')) {
        res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司/g, CompanyType.JFXD_COMPANY.company)
      }
      setAgreementContent(res.content)
    }
    getAgreementInfo()
  }, [])

  return (
    <div className={classNames(styles.registerAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title={ agreementTitle } />
      }
      {
        <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
      }
    </div>
  )
}

export default ProtocolComponent