// 下载
import React, { useEffect, useState } from 'react'
import styles from './crm-login.module.scss'
import indexApi from '@/apis'
import activityApi from '@/apis/activity'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { isPhone } from '@bihu/common-js'
import Icon from '@/components/icon/icon'
import NumberInput from '@/components/number-input/number-input'
import classNames from 'classnames'
import { Toast } from 'antd-mobile'
interface Props {}

const CrmLogin: React.FC<Props> = () => {
  const hostNameObj = getHostNameObj()
  // 微信登录code
  const [weChatCode, setWeChatCode] = useState<any>('')

  // 手机号码
  const [mobile, setMobile] = useState('')

  // 验证码
  const [code, setCode] = useState('')

  // 是否已经提交过
  const [submitSuccess, setSubmitSuccess] = useState(false)

  useEffect(() => {
    const wechatRedirectCode = getUrlKeys('code')
    if (!wechatRedirectCode) {
      weChatAuth()
      return
    }
    setWeChatCode(wechatRedirectCode)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 微信登录
  const weChatAuth = () => {
    window.location.replace(
      `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf63e127801d60888&redirect_uri=${encodeURIComponent(
        window.location.origin + window.location.pathname
      )}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`
    )
  }

  // 获取url上的微信code
  const getUrlKeys = (name:any) => {
    return (
      decodeURIComponent(
        (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.href) || [
          '',
          ''
        ])[1].replace(/\+/g, '%20')
      ) || null
    )
  }

  // 定时器对象
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>()
  // 当前倒计时剩余时间
  const [time, setTime] = useState(0)

  // 倒计时时间
  const countDownTime = 60
  // 倒计时函数
  const countDownFn = () => {
    setTime(countDownTime)
  }

  useEffect(() => {
    if (time > 0) {
      let t = setTimeout(() => {
        setTime(time - 1)
      }, 1000)
      setTimer(t)
    } else {
      timer && clearTimeout(timer)
      setTimer(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  // 获取验证码
  const getCode = async() => {
    if (!mobile) {
      return Toast.show('请输入手机号码')
    }
    if (!isPhone(mobile)) {
      return Toast.show('手机号码格式有误')
    }
    countDownFn()

    try {
      await activityApi.crmSmsSend({
        mobile
      })
    } catch (error) {
      timer && clearTimeout(timer)
    }
  }

  // 提交
  const submitCrmAccount = () => {
    if (submitSuccess) {
      return Toast.show('你已开通成功，如需修改手机号，请退出页面后重新授权')
    }
    if (!mobile) {
      return Toast.show('请输入手机号码')
    }
    if (!code) {
      return Toast.show('请输入验证码')
    }
    async function authCrmAccount() {
      const res = await indexApi.authCrmAccount({
        mobile,
        code,
        wxCode: weChatCode
      })
      setSubmitSuccess(true)
      Toast.show('通知申请成功，请退出该页面')
    }
    authCrmAccount()
  }


  return (
    <div className={styles.crmLogin}>
      <div className={styles.form}>
        <div className={styles.phoneBox}>
          <div className={styles.label}>手机号</div>
          <NumberInput
            placeholder="输入手机号"
            value={mobile}
            numberFormat={{
              decimal: false,
              negative: false,
              leadingZero: false,
              maxLength: 11
            }}
            onChange={val => {
              setMobile(val)
            }}
          />
        </div>
        <div className={styles.inputBox}>
          <NumberInput
            placeholder="请输入验证码"
            value={code}
            numberFormat={{
              decimal: false,
              negative: false,
              maxLength: 6
            }}
            onChange={val => {
              setCode(val)
            }}
          />
          {
            time ? <span className={classNames(styles.code, styles.codeText)}>剩余 {time}s</span>
              : <span className={styles.code} onClick={getCode}>获取验证码</span>
          }
        </div>

        <div className={classNames(styles.downloadBtn)} onClick={submitCrmAccount}>
          CRM账号认证
        </div>
      </div>

      <div className={styles.followBox}>
        <div className={styles.followCode}></div>
      </div>
    </div>
  )
}

export default CrmLogin
