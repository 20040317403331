// 我的
import React from 'react'
import styles from './apply_my.module.scss'
import { List, Modal, Button } from 'antd-mobile'
import Icon from '@/components/icon/icon'
import { logout, selectUserInfo } from '@/store/user'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import ApplyTabbar from '../components/apply_tabbar/apply_tabbar'
import indexApi from '@/apis'
import { isIOS } from '@bihu/common-js'
import { useNavigate } from 'react-router-dom'

interface Props {}

const My: React.FC<Props> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)

  const goto = (url:string) => {
    navigate(`/${url}`)
  }

  // 退出登录
  const logoutHandler = () => {
    const modal = Modal.show({
      bodyClassName: styles.bodyClassName,
      content: <div className={styles.modalContent}>
        <div className={styles.title}>是否退出当前账号？</div>
        <div className={styles.btnBox}>
          <Button className={styles.btn} onClick={() => modal.close()}>取消</Button>
          <Button className={styles.btn} onClick={() => {
            handleLayout()
          }}>退出</Button>
        </div>
      </div>
    })
  }

  const handleLayout = async() => {
    await indexApi.logout()
    dispatch(logout())
    if (isIOS) {
      window.webkit?.messageHandlers.iosLoginOut.postMessage(null)
    }
  }

  return (
    <div className={styles.my}>
      <header className={styles.header}>
        <div className={styles.phoneBox}>
          <span>Hi，{userInfo && userInfo.mobile.replace(/(\d{3})(\d{4})(\d{4})/g, '$1****$3')}</span>
          <span className={styles.circle}>
            Hi
          </span>
        </div>
      </header>
      <div className={styles.listBox}>
        <div className={styles.title}>个人中心</div>
        <List style={{
          '--padding-left': '0',
          '--padding-right': '0',
          '--border-bottom': 'none',
          '--font-size': '14px',
          '--prefix-width': '26px'
        }}>
          <List.Item prefix={<Icon className={styles.icon} type="personal-data" />} onClick={() => goto('register-agreement?isShowHeader=true')}>
          注册协议
          </List.Item>
          <List.Item prefix={<Icon className={styles.icon} type="application-record" />} onClick={() => goto('privacy-agreement?isShowHeader=true')}>
          隐私政策
          </List.Item>
        </List>
      </div>
      <div className={styles.logout} onClick={logoutHandler}>退出登录</div>

      <ApplyTabbar />
    </div>
  )
}

export default My
