/* eslint-disable no-console */
/* 下载应用页面 **/
import React, { useEffect, useState } from 'react'
import styles from './success.module.scss'
import { Button, Toast } from 'antd-mobile'
import { download, isIOS, isInWeChat } from '@bihu/common-js'

import classnames from 'classnames'
import arrow from '@imgs/icon-arrow.svg'
import getHostNameObj from '@/hooks/get-host-name-obj'
// import { useSearchParams } from 'react-router-dom'

import activityApi from '@/apis/activity'

const Download = () => {
  const hostNameObj = getHostNameObj()
  // const [search] = useSearchParams()

  // const [fromPage, setFromPage] = useState<string|null>() // 来自哪个页面
  const [isIos, setIsIos] = useState<boolean>(isInWeChat) // 是否ios系统
  const [isShowTips, setIsShowTips] = useState<boolean>(false) // 是否打开提示
  const [androidUrl, setAndroidUrl] = useState('')
  const [iosUrl, setIosUrl] = useState('')

  useEffect(() => {

    // setFromPage(search.get('type'))

    setIsIos(isIOS)

    // 获取app下载url
    async function getAppDownloadUrl() {

      const res = await activityApi.getNewestVersion({
        appType: hostNameObj.appType
      })
      setAndroidUrl(res.androidPackageLocation)
      setIosUrl(res.iosPackageLocation)
      // const res = await activityApi.getAppDownloadUrl()
      // let appUrl = 'https://yuanxin-app.oss-cn-shenzhen.aliyuncs.com/'
      // if (process.env.REACT_APP_API_ENV === 'development') {
      //   appUrl += `qa/${res['url-prod']}`
      // } else {
      //   appUrl += `prod/${res['url-qa']}`
      // }
      // console.log('appUrl', appUrl)
      // console.log('res.ios', res.ios)

      // setAndroidUrl(appUrl)
      // setIosUrl(res.ios)

      // 上报用户下载APP
      setTimeout(() => {
        activityApi.reportUserDownloadApp()
      }, 0)
    }
    getAppDownloadUrl()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 监听已有下载链接，就自动下载
  useEffect(() => {
    if (androidUrl) {
      if (isIOS) {
        openTips()
        window.location.href = iosUrl
      } else {
        openTips()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [androidUrl, iosUrl])

  // 打开提示
  const openTips = () => {
    let isWechat = isInWeChat
    if (isWechat) {
      setIsShowTips(true)
    } else {
      downloadApp()
    }
  }

  // 下载文件
  const downloadApp = () => {
    if (androidUrl) {
      download(androidUrl, '元信', 'apk')
    } else {
      Toast.show('下载链接已准备，请重试点击下载APP')
    }
  }

  return <div className={styles.successPage}>
    <div className={styles.successIcon}></div>
    <div className={styles.title}>申请成功</div>
    <div className={styles.text}>您已满足借款初审条件</div>
    <div className={styles.textSecondary}>
        请下载<span className={styles.bold}>{hostNameObj.appName}</span>领取额度
    </div>

    {
      isIos ? <a className={classnames(styles.downloadBtn, styles.downloadIos)} href={iosUrl}>下载APP</a>
        : <Button color="primary" className={styles.downloadBtn} onClick={() => openTips()}>下载APP</Button>
    }
    {
      isShowTips ? <div className={styles.tips} onClick={() => setIsShowTips(false)}>
        <img src={arrow} alt="arrow" />
        <div className={styles.tipsTitle} >
          <p>请点击右上角的...</p>
          <p>选择“在浏览器中打开”下载</p>
        </div>
      </div> : null
    }
  </div>

}

export default Download