// 重置弹窗样式组件
import React, { useImperativeHandle, useRef } from 'react'
import styles from './reset-modal.module.scss'
import { Modal } from 'antd-mobile'
import { ModalProps } from 'antd-mobile/es/components/modal/modal'
import { ModalShowHandler } from 'antd-mobile/es/components/modal/show'
import Icon from '@/components/icon/icon'

// 外部使用useRef的时候可以使用 例如：const ResetModalRef = useRef<ResetModalProps>(null)
export interface ResetModalProps extends ModalProps{
  show: () => void,
  close: () => void,
}

interface Props{
  children: React.ReactNode,
}
const resetModal = React.forwardRef<ResetModalProps, Props>((props, ref) => {

  const modalRef = useRef<ModalShowHandler>()

  // 显示
  const show = () => {
    modalRef.current = Modal.show({
      ...props,
      bodyClassName: styles.ModalBodyClassName,
      content: <div className={styles.modalBox}>
        <div className={styles.content}>
          {props.children}
        </div>
        <div className={styles.closeBox}>
          <Icon type="close" className={styles.close} onClick={() => modalRef.current?.close()} />
        </div>
      </div>
    })
  }

  // 关闭
  const close = () => {
    modalRef.current?.close()
  }

  useImperativeHandle(ref, () => (
    {
      children: props.children,
      show,
      close
    }
  ))

  return <></>
})

export default resetModal
