/* eslint-disable no-magic-numbers */
// 广告页
import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './advertising.module.scss'
import classNames from 'classnames'
import { Toast, Button, Swiper, NoticeBar } from 'antd-mobile'
import { isIOS, Storage } from '@bihu/common-js'
import indexApi from '@/apis'
import activityApi from '@/apis/activity'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TOKEN } from '@/constants/storage'
import { useAppDispatch } from '@/store/hooks'
import { logout } from '@/store/user'
import ProductItem from './components/product-item/product-item'
import bannerApi, { HomeDialogBannerResponse } from '@/apis/banner'
import { jumpInlinePage, reportProductExposure } from '@/utils/utils'
import { ENTRANCE_TYPE } from '@/constants/common'
import { EntranceType } from '@/types/apis/index'
import JointLoginPopup from '@/components/joint-login-popup/joint-login-popup'
import DetainmentPopup, { DetainmentPopupProps } from '@/components/detainment-popup/detainment-popup'
import HomePopup, { HomePopupProps } from '@/components/home-popup/home-popup'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
interface Props {
  productName?: '', // 产品名字
  applyStatus?: number, // hold单状态
  revisit?: number, // 二次回访结果页
}

const IosSkin: React.FC<Props> = props => {
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const { productName, applyStatus, revisit } = props
  const JointLoginModalRef: any = useRef()
  const detainmentPopupRef = useRef<DetainmentPopupProps>(null)
  const HomePopupRef = useRef<HomePopupProps>(null)
  const [banners, setBanners] = useState<any>([])
  const [token, setToken] = useState('')
  const [isAutoPlay, setIsAutoPlay] = useState(true)
  const [is360SpecialCity, setIs360SpecialCity] = useState(false)
  const [showPage, setShowPage] = useState(false)
  const [search] = useSearchParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  // Retain参数代表是从本success页面跳转到挽留页面
  const isRetain = search.get('retain')
  useEffect(() => {
    const urlToken = search.get('token')
    if (urlToken) {
      sessionStorage.setItem('FLATFORM_SOURCE', 'YqzsApp')
      Storage.set(TOKEN, urlToken)
      setToken(urlToken)
    } else {
      const localToken = localStorage.getItem(TOKEN)
      if (localToken) {
        setToken(localToken)
      }
    }
  }, [])

  // 获取用户来源与城市
  useEffect(() => {
    async function getUserDetailVO() {
      let whitelistCitys = ['北京市', '上海市', '福州市']
      const res:any = await activityApi.getUserDetailVO()
      const adChannelCode = res.lastLoginAdChannelSimpleVO ? res.lastLoginAdChannelSimpleVO.adChannelCode : ''
      if (whitelistCitys.indexOf(res.cityName) !== -1 && adChannelCode === 'hBSfjw') {
        // 走360B面
        navigate('/apply_home')
      } else {
        setTimeout(() => {
          setShowPage(true)
        }, 200)
      }
    }
    if (token) {
      getUserDetailVO()
    }
  }, [token])

  // 获取顶部推荐
  useEffect(() => {
    async function homeProductList() {
      const res:any = await indexApi.topRecommend()
      const tempBanners = res.map((item:any) => {
        item.lendingRateString = Math.round(Math.random() * 6 + 88)
        item.dayInterestRate = (item.monthlyInterestRate / 30).toFixed(2)
        return item
      })
      setBanners([...tempBanners])
    }
    if (token) {
      homeProductList()
      homeCarouselBanner()
      // getAdvantageList()
    }
  }, [token])
  // 中插图片数组
  const [homeCarouselBannerArr, setHomeCarouselBannerArr] = useState<HomeDialogBannerResponse[]>([])
  // 获取首页中插广告
  const homeCarouselBanner = async() => {
    const res = await bannerApi.homeCarouselBanner()
    setHomeCarouselBannerArr(res)
  }
  const [middleInsertProductIds, setMiddleInsertProductIds] = useState<any>([])
  // 中插图片切换时触发
  const onHomeCarouselBannerChange = (index:number) => {
    const homeCarouseBannerId = homeCarouselBannerArr[index].productId
    // 判断是否已经曝光过
    const isExposure = middleInsertProductIds.find((item:any) => item === homeCarouseBannerId)
    if (isExposure) {
      return
    }
    middleInsertProductIds.push(homeCarouseBannerId)
    setMiddleInsertProductIds([...middleInsertProductIds])
    // 上报产品曝光
    reportProductExposure(homeCarouseBannerId, ENTRANCE_TYPE.HOME_PAGE_INSERTION, isRetain ? 5 : 4)
  }

  const [hotRecommends, setHotRecommends] = useState<any>([])
  const [jumboLoanRecommends, setJumboLoanRecommends] = useState<any>([])
  const [microfinanceRecommends, setMicrofinanceRecommends] = useState<any>([])
  // 获取九宫格产品列表
  useEffect(() => {
    async function homeProductList() {
      // 判断是否为挽留页面，如果挽留页面则appH5为5，否则为4
      const res:any = await indexApi.homeProductList({ appH5: isRetain ? 5 : 4 })
      setHotRecommends(res.hotRecommends)
      setJumboLoanRecommends(res.jumboLoanRecommends)
      setMicrofinanceRecommends(res.microfinanceRecommends)
    }
    if (token) {
      homeProductList()
    }
  }, [token])

  const applyProduct = async(item:any, entranceType:any) => {
    // 如果是首页弹窗或首页中插，取productId字段，否则取id字段
    const applyId = (entranceType === ENTRANCE_TYPE.HOME_PAGE_POPUP || entranceType === ENTRANCE_TYPE.HOME_PAGE_INSERTION) ? item.productId : item.id
    // 判断是否为挽留页面，如果挽留页面则appH5为5，否则为4
    let res:any = await indexApi.applyFor({
      productId: applyId,
      entranceType,
      appH5: isRetain ? 5 : 4
    })
    // 判断是否联登
    if (res?.unionLoginFlag === 1) {
      const params = {
        productId: applyId,
        productName: item.productName,
        productLogo: item.productLogo,
        h5Protocols: res.h5Protocols,
        loansLimitMax: item.loansLimitMax,
        entranceType,
        appH5: isRetain ? 5 : 4
      }
      // 打开产品联登授权弹窗
      JointLoginModalRef.current?.init(params)
      return
    }
    const isHttpsUrl = isHttpsUsingURL(res.thirdpartyTargetUrl)
    console.log('是否https', isHttpsUrl)
    if (isHttpsUrl) {
      navigate(`/inline-page?url=${encodeURIComponent(JSON.stringify(res.thirdpartyTargetUrl))}${item.productName ? `&productName=${item.productName}` : ''}`)
    } else {
      window.location.href = res.thirdpartyTargetUrl
    }
  }

  const isHttpsUsingURL = (url:string) => {
    const match = url.match(/^(https?):\/\//)
    if (match && match[1] === 'https') {
      return true
    } else {
      return false
    }
  }

  const formSwiper = useRef<SwiperRef>(null)
  const [currentSwiperIndex, setCurrentSwiperIndex] = useState(0)
  const [topRecommendProductIds, setTopRecommendProductIds] = useState<any>([])

  const onChangeSwiper = (index:number) => {
    setCurrentSwiperIndex(index)
    const topRecommendBannerId = banners[index].id
    // 判断是否已经曝光过
    const isExposure = topRecommendProductIds.find((item:any) => item === topRecommendBannerId)
    if (isExposure) {
      return
    }
    topRecommendProductIds.push(topRecommendBannerId)
    setTopRecommendProductIds([...topRecommendProductIds])
    reportProductExposure(topRecommendBannerId, ENTRANCE_TYPE.TOP_RECOMMENDATIONS, isRetain ? 5 : 4)
  }

  // 千位分隔符
  const numFormat = (num:number) => {
    let res = num.toString().replace(/\d+/, n => { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, $1 => {
        return `${$1},`
      })
    })
    return res
  }

  const handleLayout = async() => {
    setIsAutoPlay(false)
    await indexApi.logout()
    dispatch(logout())
    if (isIOS) {
      window.webkit.messageHandlers.iosLoginOut.postMessage(null)
    }
  }

  // 跳转之前判断
  const jumpAfter = (obj:HomeDialogBannerResponse, entranceType:EntranceType) => {
    // linkType: number, // 关联方式 1-关联产品 2-Banner链接
    if (obj.linkType === 1) {
      jumpInlinePage(obj.productId, entranceType, obj.title)
    } else {
      navigate(`/inline-page?url=${encodeURIComponent(JSON.stringify(obj.bannerTargetUrl))}${obj.title ? `&productName=${obj.title}` : ''}`)
    }
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // 当页面变为可见时执行的操作
        if (sessionStorage.getItem('NeedDetainment')) {
          detainmentPopupRef.current?.close()
          setTimeout(() => {
            sessionStorage.removeItem('NeedDetainment')
            detainmentPopupRef.current?.show()
          }, 20)
        }
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const swiperItems = banners.map((swiperItem:any, swiperIndex:any) => (
    <Swiper.Item key={swiperIndex}>
      <div className={styles.swiperBoxItem} onClick={() => applyProduct(swiperItem, ENTRANCE_TYPE.TOP_RECOMMENDATIONS)}>
        <img className={styles.jingxuan} src={require('@imgs/yqzs/jingxuan.png')} alt="jingxuan" />
        <div className={styles.productHeader}>
          <img className={styles.productLogo} src={swiperItem.productLogo} alt="productLogo" />
          <div className={styles.productName}>{ swiperItem?.productNickName }</div>
        </div>
        <div className={styles.content}>
          <div className={styles.limitBox}>
            <div className={styles.limitBoxLeft}>
              <div className={styles.limitLabel}>最高可借(元)</div>
              <div className={styles.limitValue}>{ swiperItem.loansLimitMax }</div>
            </div>
            <div className={styles.limitBtn}>领取额度</div>
          </div>
          <div className={styles.productOtherInfo}>
            <div className={styles.productOtherInfoItem}>日利率{swiperItem.dayInterestRate}%起</div>
            <div className={styles.productOtherInfoItem}>最长36期</div>
          </div>
        </div>
      </div>
    </Swiper.Item>
  ))

  return (
    <div className={styles.advertising}>
      <div>
        {
          showPage ? <>
            <div className={styles.pageTitle}>有钱钱包</div>
            <NoticeBar className={styles.noticeBar} content={'温馨提示：谨防电信网络诈骗，保护好个人信息，贷款、购物退款联系官方客服，以“退款、刷流水、保证金、解冻费”等名义先接纳各种费用都属于诈骗，请注意防范，不要添加陌生人微信，不要向陌生人转账。'} />
            <div className={styles.swiperBox}>
              <Swiper
                ref={formSwiper}
                indicator={() => null}
                loop
                autoplay={isAutoPlay}
                autoplayInterval={4000}
                defaultIndex={currentSwiperIndex}
                onIndexChange={onChangeSwiper}
              >
                {swiperItems}
              </Swiper>
            </div>
            {
              homeCarouselBannerArr.length > 0 && <div className={styles.homeCarouselBannerBox}>
                <Swiper
                  autoplay
                  loop
                  style={{
                    '--border-radius': '8px',
                  }}
                  indicator={() => null}
                  onIndexChange={onHomeCarouselBannerChange}
                >
                  {
                    homeCarouselBannerArr.map((item, index) => (
                      <Swiper.Item key={index} onClick={() => jumpAfter(item, ENTRANCE_TYPE.HOME_PAGE_INSERTION)}>
                        <div
                          className={styles.homeCarouselBannerItem}
                        >
                          <img src={item.bannerUrl} alt={item.title} className={styles.img} />
                        </div>
                      </Swiper.Item>
                    ))
                  }
                </Swiper>
              </div>
            }
            <div className={styles.productModule}>
              {
                hotRecommends.length > 0 && <div className={styles.productModuleItem}>
                  <div className={styles.productModuleTitleBox}>
                    <div className={styles.productModuleTitleBefore}></div>
                    <div className={styles.productModuleTitle}>今日爆款</div>
                  </div>
                  <div className={styles.productList}>
                    {
                      hotRecommends.map((item:any) => <div key={item.id} onClick={() => applyProduct(item, ENTRANCE_TYPE.LIST_OF_PRODUCTS)}><ProductItem pruductDetail={item} mark={'HOT'}></ProductItem></div>)
                    }
                  </div>
                </div>
              }
              {
                microfinanceRecommends.length > 0 && <div className={styles.productModuleItem}>
                  <div className={styles.productModuleTitleBox}>
                    <div className={styles.productModuleTitleBefore}></div>
                    <div className={styles.productModuleTitle}>小额借得到</div>
                  </div>
                  <div className={styles.productList}>
                    {
                      microfinanceRecommends.map((item:any) => <div key={item.id} onClick={() => applyProduct(item, ENTRANCE_TYPE.LIST_OF_PRODUCTS)}><ProductItem pruductDetail={item}></ProductItem></div>)
                    }
                  </div>
                </div>
              }
              {
                jumboLoanRecommends.length > 0 && <div className={styles.productModuleItem}>
                  <div className={styles.productModuleTitleBox}>
                    <div className={styles.productModuleTitleBefore}></div>
                    <div className={styles.productModuleTitle}>大额低息</div>
                  </div>
                  <div className={styles.productList}>
                    {
                      jumboLoanRecommends.map((item:any) => <div key={item.id} onClick={() => applyProduct(item, ENTRANCE_TYPE.LIST_OF_PRODUCTS)}><ProductItem pruductDetail={item}></ProductItem></div>)
                    }
                  </div>
                </div>
              }
            </div>
            {/* 从挽留页面进入显示有钱钱包logo */}
            <img className={styles.logoText} src={isRetain ? require('@imgs/yqzs/youqianqianbao-text.png') : require('@imgs/yqzs/logo-text.png')} />
            <div className={styles.stepBox}>
              <div className={styles.stepItem}>
                <img className={styles.stepItemIcon} src={require('@imgs/yqzs/step-ic-1.png')} />
                <div className={styles.stepItemName}>01 领取额度</div>
              </div>
              <img className={styles.dashedline1} src={require('@imgs/yqzs/dashedline.png')} alt="jingxuan" />
              <div className={styles.stepItem}>
                <img className={styles.stepItemIcon} src={require('@imgs/yqzs/step-ic-2.png')} />
                <div className={styles.stepItemName}>02 申请借款</div>
              </div>
              <img className={styles.dashedline2} src={require('@imgs/yqzs/dashedline.png')} alt="jingxuan" />
              <div className={styles.stepItem}>
                <img className={styles.stepItemIcon} src={require('@imgs/yqzs/step-ic-3.png')} />
                <div className={styles.stepItemName}>03 机构放款</div>
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.footerText1}>本平台是金融机构第三方互联网营销平台，为客户提供贷款推荐和咨询服务，放款由第三方金融机构提供。请根据个人能力合理贷款，理性消费，避免逾期。所有贷款申请在未成功贷款前绝不收取任何费用，为了保证您的资金安全，请不要相信任何要求您支付费用的信息、邮件、电话等不实信息。</div>
              <div className={styles.footerText2}>{channelValue?.includes('kuaishou') && isRetain ? '闽ICP备2020020353号' : '粤ICP备16075822号-2A'} </div>
            </div>
            <DetainmentPopup
              ref={detainmentPopupRef}
              callBack={(product, entranceType) => applyProduct(product, entranceType)}
            />
            <HomePopup
              ref={HomePopupRef}
              isRetain={isRetain}
              callBack={(product, entranceType) => applyProduct(product, entranceType)}
            />
          </> : <>
            <div className={styles.root}>
              <div id="loading-box">
                <div className={styles.ballClipRotateMultiple}>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
      <JointLoginPopup ref={JointLoginModalRef}></JointLoginPopup>
    </div>
  )
}

export default IosSkin
