// 申请借款
import React, { useState, useRef, useEffect } from 'react'
import styles from './loan-application.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Header from '@/components/header/header'
import classNames from 'classnames'
import { Popup, Button, Input, Toast } from 'antd-mobile'
import Icon from '@/components/icon/icon'
import { formatTime, parseNum } from '@bihu/common-js'
import DetainmentPopup, { DetainmentPopupProps } from '@/components/detainment-popup/detainment-popup'
import productApi, { ProductDetailResponse } from '@/apis/product'
import BigNumber from 'bignumber.js'
import { EntranceType } from '@/types/apis/index'
import Event from '@/event'
import { APPLY_STATUS_MODAL } from '@/event/constant'

interface Props {}

const LoanApplication: React.FC<Props> = props => {

  const navigate = useNavigate()
  const [search] = useSearchParams()

  const [title, setTitle] = useState<string>('元信')
  // 产品id
  const [productId, setProductId] = useState<string>('')
  // 入口类型
  const [entranceType, setEntranceType] = useState<EntranceType>()
  // 产品
  const [product, setProduct] = useState<ProductDetailResponse>()
  // 借款金额
  const [amount, setAmount] = useState<string>('')

  // 挽留弹窗ref
  const detainmentPopupRef = useRef<DetainmentPopupProps>(null)

  // 返回
  const back = () => {
    const productBack = localStorage.getItem('productBack')

    try {
      if (productBack === formatTime(Date.now(), 'YYYY-MM-DD')) {
        if (document.referrer) {
          navigate(-1)
        } else {
          navigate('/')
        }

        detainmentPopupRef.current?.close()
      } else {
        //  阻止浏览器回退事件
        detainmentPopupRef.current?.show()
        window.history.pushState(null, 'null', document.URL)
        localStorage.setItem('productBack', formatTime(Date.now(), 'YYYY-MM-DD'))
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  useEffect(() => {
    // 获取产品名称
    const titleTemp = search.get('productName')
    if (titleTemp) {
      setTitle(titleTemp)
    }
    // 获取产品id
    const productIdTemp = search.get('productId')
    if (productIdTemp) {
      setProductId(productIdTemp)
    }
    // 获取入口类型
    const entranceTypeTemp = search.get('entranceType')
    if (entranceTypeTemp) {
      setEntranceType(+entranceTypeTemp as EntranceType)
    }

    localStorage.removeItem('productBack')
    // 监听浏览器回退事件
    // window.addEventListener('popstate', back, false)
    window.onpopstate = back
    window.history.pushState(null, 'null', document.URL)

    return () => {
      // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
      // window.removeEventListener('popstate', back, false)
      window.onpopstate = null
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 获取产品详情
    const getDetail = async() => {
      const res = await productApi.detail({
        productId: +productId
      })
      setProduct(res)
      setAmount(parseNum(res.loansLimitMax))
    }

    if (productId) {
      getDetail()
    }
  }, [productId])


  // 是否显示弹出层
  const [visible, setVisible] = useState(false)
  // 当前选中月份
  // eslint-disable-next-line no-magic-numbers
  const [currentMonth, setCurrentMonth] = useState<number>(12)

  // 月份数组
  const monthArr = [
    {
      key: 1,
      num: 3,
    },
    {
      key: 2,
      num: 6,
    },
    {
      key: 3,
      num: 12,
    },
    {
      key: 4,
      num: 24,
    },
    {
      key: 5,
      num: 36,
    },
  ]

  // 选择月份
  const selectMonth = (num: number) => {
    setCurrentMonth(num)
    setVisible(false)
  }

  // 借款金额改变
  const amountOnChange = (str: string) => {
    str = str.replace(/[^\d]/g, '')
    let newValue:number | string = str
    if (product) {
      if (+str > product.loansLimitMax) {
        newValue = product.loansLimitMax
      }
    }
    newValue = parseNum(newValue as number)
    if (newValue && newValue !== '0') {
      setAmount(newValue)
    } else {
      setAmount('')
    }
  }

  // 每月需还金额
  const [monthlyRepaymentAmount, setMonthlyRepaymentAmount] = useState<number>(0)

  useEffect(() => {
    if (amount && product && currentMonth) {
      const amountNew = amount.replace(',', '')
      const monthRate = new BigNumber(product.monthlyInterestRate).div(100)

      const powNum = new BigNumber(1).plus(monthRate)
        .pow(currentMonth)
      let val = new BigNumber(amountNew).multipliedBy(monthRate)
        .multipliedBy(powNum)
        .div(powNum.minus(1))
        .toFixed(2)
      setMonthlyRepaymentAmount(Number(val))
    } else {
      setMonthlyRepaymentAmount(0)
    }
  }, [currentMonth, amount, product])

  // 预估利息 = 每月还款额*期数-借款金额
  const [repaymentInterest, setRepaymentInterest] = useState<number>(0)
  useEffect(() => {
    if (currentMonth && amount && monthlyRepaymentAmount) {
      const amountNew = amount.replace(',', '')

      const val = new BigNumber(monthlyRepaymentAmount).multipliedBy(currentMonth)
        .minus(amountNew)
        .toFixed(2)

      setRepaymentInterest(Number(val))
    }
  }, [currentMonth, amount, monthlyRepaymentAmount])

  // 立即申请
  const apply = async() => {
    if (!amount) {
      return Toast.show('请输入借款金额')
    }
    // console.log('立即申请', amount, amount.replace(',', ''))
    let newAmount = parseInt(amount.replace(',', '') || '0')
    // console.log('newAmount', newAmount)

    if (product) {
      if (newAmount < product.loansLimitMin) {
        return Toast.show(`借款金额不能小于${parseNum(product?.loansLimitMin)}`)
      }

      if (!currentMonth) {
        return Toast.show('请选择借款时长')
      }

      const res = await productApi.apply({
        productId: +productId,
        loansAmount: newAmount,
        loansDuration: currentMonth,
        entranceType
      })

      if (res.applyStatus === 0 || res.applyStatus === 2) {
        Event.emit(APPLY_STATUS_MODAL, {
          ...res,
          productName: title
        })
      } else {
        navigate(`/inline-page?url=${res}${title ? `&productName=${title}` : ''}`)
      }
    }
  }

  interface AskConditionType{
    label: string
  }

  return (
    <div className={styles.loanApplication}>
      <section className={styles.top}>
        <Header title={title} className={styles.header} />
        <div className={classNames(styles.borrowBox)}>
          {
            product
            && <div className={styles.describe}>借多少({parseNum(product?.loansLimitMin)}-{parseNum(product?.loansLimitMax)}元)</div>
          }

          <div className={styles.inputBox}>
            <span>
                ￥
            </span>
            <Input placeholder="" value={amount} onChange={amountOnChange} />
          </div>
          <div className={styles.selectMonthBox}>
            <div>借多久</div>
            <div className={classNames(styles.select, currentMonth && styles.currentMonth)} onClick={() => setVisible(true)}>
              {
                currentMonth ? `${currentMonth} 个月` : '请选择'
              }
            </div>
          </div>
        </div>
      </section>

      <section className={classNames(styles.block, styles.calculateBox)}>
        <div>
          <div className={styles.label}>参考月利率</div>
          <div className={styles.value}>{product?.monthlyInterestRate}%</div>
        </div>

        <div>
          <div className={styles.label}>预估利息(元)</div>
          <div className={classNames(styles.value, !repaymentInterest && styles.grayColor)}>{repaymentInterest ? parseNum(repaymentInterest) : '-'}</div>
        </div>
        <div>
          <div className={styles.label}>月均还款(元)</div>
          <div className={classNames(styles.value, !monthlyRepaymentAmount && styles.grayColor)}>
            {monthlyRepaymentAmount ? parseNum(monthlyRepaymentAmount) : '-'}
          </div>
        </div>
      </section>

      <section className={styles.block}>
        <div className={classNames(styles.title, styles.marginTop0)}>申请条件</div>
        <div className={styles.askCondition}>
          {
            product?.askCondition && JSON.parse(product.askCondition).map((item:AskConditionType) => {
              return <div key={item.label}>
                <Icon type="check-circle" className={styles.checkCircle} />
                <span className={styles.name}>{item.label}</span>
              </div>
            })
          }
        </div>
        <div className={styles.title}>产品介绍</div>
        <div className={styles.content}>{product?.introduction}</div>
        <div className={styles.title}>机构信息</div>
        <div className={styles.content}>公司主体：{product?.productCompany}</div>
        <div className={styles.content}>联系客服：{product?.contactNumber || '-'}</div>
      </section>

      <footer className={styles.footer}>
        <Button className={styles.btn} onClick={apply}>立即申请</Button>
      </footer>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
      >
        <ul className={styles.monthBox}>
          {
            monthArr.map(item => {
              return <li
                key={item.key}
                className={classNames(styles.row, item.num === currentMonth && styles.active)}
                onClick={() => selectMonth(item.num)}
              >
                {item.num} 个月
              </li>
            })
          }
        </ul>
      </Popup>

      <DetainmentPopup ref={detainmentPopupRef} />
    </div>
  )
}

export default LoanApplication
